import { dayjs as _dayjs } from "./index";
import query from "./query";
import { pascalCase } from "change-case";
import { DEBUG_APP } from "src/store";
import { USER } from "src/lib/auth";
import { camelCase } from "lodash";

type mutationOption = "create" | "update";

function mapGqlType<T extends TschemaTableName, K extends keyof TschemaType<T>>(tN: T, field: K): string {
	// @ts-ignore
	const fieldDef = lib.schema[tN][field] as TschemaFieldDefinition;

	const { type } = fieldDef;

	const map = {
		string: "String",
		integer: "Int",
		datetime: "DateTime",
		date: "JustDate",
		boolean: "Boolean",
		decimal: "Float",
		float: "Float",
		enum: `${pascalCase(tN)}${pascalCase(field as string)}Enum`,
		"text ARRAY": "[String]",
		"string ARRAY": "[String]",
		time: "String",
		integerArray: "[Int]",
		date_time: "DateTime",
		text: "String",
		text_array: "[String]",
		json: "JSONObject",
		string_array: "[String]"
	};

	if (type in map) {
		return map[type];
	} else if (["link", "foreign"].includes(type)) {
		return fieldDef?.foreign?.type === "string" ? "String" : "ID";
	} else if (type === "externalLink") {
		return map[fieldDef[type].type];
	} else {
		console.error("Failed to get type of", tN, field);
		return "String";
	}
}

export async function mutateVared<T extends TschemaTableName, N extends mutationOption, R extends true | false = false>(
	config: {
		tableName: T;
		mutation?: N;
		id?: N extends "update" ? string | number : never;
		raw?: R;
		rawName?: string;
		forceAttachments?: boolean;
		return_raw?: boolean;
	},
	_data: Partial<TschemaType<T>>
): Promise<R extends false ? number : string> {
	const data = lib.prepareForMutation(config.tableName, _data);
	// console.log(data);
	if (lib.notIn(config.tableName, lib.schema) && camelCase(config.tableName) in lib.schema) {
		console.groupCollapsed("IMPLEMENTATION ON MUTATEVARED");
		console.trace();
		console.groupEnd();
		config.tableName = camelCase(config.tableName);
	}
	console.log({ MUTATE_VARED: { config, data } });
	const user = lib.getStoreValue(USER);
	if (config.mutation === "create" && !!lib.schema[config.tableName]?.Author) {
		data.Author = String(user?.id);
	}
	if (config.mutation === "update" && !!lib.schema[config.tableName]?.LastModifiedBy) {
		data.LastModifiedBy = String(user?.id);
	}

	if (config.id) {
		data.id = config.id;
	}
	if (!config.mutation) {
		// @ts-ignore
		config.mutation = data.id ? "update" : "create";
	}

	const variables = Object.entries(lib.schema[config.tableName])
		.map(([field, def]) => {
			return def.reverse == true || def.type === "link"
				? false
				: ["id", "created_at", "created_by", "updated_by", "createdAt"].includes(field)
				? false
				: !(field in data)
				? false
				: // @ts-ignore
				  `$${field}: ${mapGqlType(config.tableName, field)}`;
		})
		.filter(Boolean)
		.join(",");

	const qStrg = `
		mutation ${config.rawName || ""} ${variables ? `( ${variables} )` : ""} {
		_mutation:	${config.mutation}_${config.tableName}(data: {${Object.entries(lib.schema[config.tableName])
		.map(([key, def]) => {
			return def.reverse == true || def.type === "link"
				? false
				: ["id", "created_at", "created_by", "updated_by"].includes(key)
				? false
				: !(key in data)
				? false
				: `${key}: $${key}`;
		})
		.filter(Boolean)
		.join(",")}}${config.mutation !== "update" ? "" : `, where: ["AND",{ id: { eq: "${data.id}" } }]`}) { id } }`;

	if (typeof config.raw === "boolean" && config.raw) {
		// @ts-ignore
		return qStrg;
	}

	async function getVariables() {
		if (lib.getStoreValue(DEBUG_APP)) {
			console.groupCollapsed("mutation getVariables");
		}

		const p = {};

		for (let [key, value] of Object.entries(data)) {
			if (["id", "created_at", "created_by", "updated_by"].includes(key)) {
				continue;
			}
			const def = lib.schema[config.tableName][key];

			if (def?.type === "string_array") {
				if (Array.isNotEmpty(value) && !value.map((v) => typeof v === "string").includes(false)) {
					p[key] = value;
				} else if (typeof value === "string") {
					p[key] = [value];
				}
			} else if (typeof value === "undefined" || value === "") {
				if (lib.getStoreValue(DEBUG_APP)) {
					console.log("ignoring because", key, "is undefined or empty string:", value);
				}
				if (value === "") {
					p[key] = null;
				}
			} else if (def?.type === "enum") {
				if (value) {
					value = value.value || value;
					if (def?.enum?.values?.includes(value) || def?.values?.includes(value)) {
						p[key] = value;
					} else {
						if (lib.getStoreValue(DEBUG_APP)) {
							console.log(
								"ignoring because",
								key,
								"detected as enum, is not part of an enum:",
								value,
								"possible values:",
								def?.enum?.values
							);
						}
					}
				} else {
					if (lib.getStoreValue(DEBUG_APP)) {
						console.log("ignoring because", key, "detected as enum, is undefined:", value);
					}
				}
			} else if (def?.type === "externalLink" && value) {
				p[key] = value;
			} else if (def?.type === "text ARRAY") {
				if (value) {
					p[key] = (value || [])
						.filter((v) => !!v)
						.map((value) => (typeof value === "object" && value.value ? value.value : value));
				}
			} else if (["string", "number", "boolean"].includes(typeof value)) {
				p[key] = value;

				if (["decimal", "float", "integer"].includes(def?.type)) {
					if (!isNaN(p[key])) {
						p[key] = +p[key];
					}
				}
				// if (def[def.type]?.type === 'string' && type) {
				// 	p[key] = String(p[key])
				// }
			} else if (value === null) {
				p[key] = value;
			} else if (typeof value === "object" && value.value) {
				p[key] = value.value;
			} else {
				if (lib.getStoreValue(DEBUG_APP)) {
					console.log("ignoring", key, "for unkown reason");
				}
			}
		}

		// console.log(out);
		// debugger;
		if (lib.getStoreValue(DEBUG_APP)) {
			console.groupEnd();
		}
		return p;
	}

	const { _mutation: out } = await query(qStrg, {
		variables: await getVariables()
	});

	return config.return_raw ? out : +out?.id;
}

export async function mutate<N extends TschemaTableName, T extends true | false = false>(
	config: {
		mutation: "create" | "update";
		tableName: N;
		id?: string | number;
		raw?: T;
		rawAlias?: string;
		style?: "normal" | "variabalised";
	},
	data: Partial<TschemaType<N>>,
	variables?: {
		[key: string]: string;
	}
) {
	return mutateVared(config, data);
}

/**
 * GQLizer linkings
 * @param {{foreign:TschemaTableName,origin:TschemaTableName,foreignId:string,originId:string,remove:boolean}} config
 * @returns {Promise<any>}
 * @method linker
 * @memberof lib
 */
export function linker<T extends TschemaTableName>(config: {
	origin: T;
	foreign: keyof Schema.FieldTypes[T];
	origin_id: string | number;
	foreign_id: string | number;
	remove?: boolean;
}) {
	const { foreign, origin, foreign_id, origin_id, remove = false } = config;
	return query(
		`mutation($origin_id: JSON, $foreign_id: JSON) {
			${"un".if(remove)}link_${origin}_${foreign}(
				where_${origin}: ["AND", { id: { eq: $origin_id } }]
				where_${foreign}: ["AND", { id: { eq: $foreign_id } }]
			)
		}`,
		{
			variables: { origin_id, foreign_id }
		}
	);
}

export function prepareForMutation(tableName: TschemaTableName, data: any = {}) {
	const out = {};
	if (lib.isObject(data)) {
		for (const field in lib.schema[tableName]) {
			const def: TschemaFieldDefinition = lib.schema[tableName][field];
			if (field in data) {
				if (["id", "created_at", "created_by", "updated_by"].includes(field)) {
					// skip - non editables
					continue;
				}

				if (["string", "text", "date", "time", "date_time"].includes(def.type)) {
					if (typeof data[field] === "string") {
						out[field] = data[field] || null;
						continue;
					}
				}
				if (["text_array", "string_array"].includes(def.type)) {
					if (Array.isArray(data[field])) {
						out[field] = data[field].map(String);
					}
				}
				if (["integer", "float"].includes(def.type)) {
					out[field] = lib.matchType(data[field], "number", null);
					continue;
				}
				if (["boolean"].includes(def.type)) {
					out[field] = lib.matchType(data[field], "boolean", false);
					continue;
				}
				if (["foreign"].includes(def.type)) {
					if (def.reverse) {
						continue;
					}
					if (def.foreign.type === "integer" && def.foreign.key === "id") {
						out[field] = (!isNaN(+data[field]) ? +data[field] : +data[field]?.[0]?.["id"]) || null;
					} else {
						out[field] = data[field] || null;
					}
					continue;
				}
				if (["enum"].includes(def.type)) {
					if (def.enum.values.includes(data[field])) {
						out[field] = data[field];
					}
				}
			}
		}
	}
	return out;
}

export function diffForMutation(tableName: TschemaTableName, old_data: any, new_data: any) {
	const _old = prepareForMutation(tableName, old_data);
	const _new = prepareForMutation(tableName, new_data);

	const diffedProps = Object.keys(_new).filter((prop) => typeof _new[prop] !== "object" && _new[prop] != _old[prop]);

	const out = {};

	for (const key of diffedProps) {
		out[key] = _new[key];
	}

	return out;
}
