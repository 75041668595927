import axios from "axios";
import { getEnv } from ".";

import { get2 } from "./get";
import { mutateVared } from "./mutate";

export const plugins = {
	gql: {
		get: get2,
		mutate: mutateVared
	},
	gqlizer: {
		async update<T extends TschemaTableName = any>(tableName: T, data: TschemaType<T>) {
			if (isNaN(data.id)) throw "NO ID";
			return await lib.query(
				`
				mutation ($data: Update${tableName}Input) {
					update${tableName}(
						data: $data,
						where: {
							id: {
								eq: "${data.id}"
							}
						}
					) {
						id
					}
				}
			`,
				{ variables: { data } }
			);
		}
	}
};

export function plugin(plugin_name: string, endpoint: string, ...path: string[]): string {
	return window.PluginsManager.endpoint(plugin_name, endpoint, ...path);
}

Object.defineProperty(window.PluginsManager, "getEndpoint", {
	value: function (plugin_name) {
		if (plugin_name === "authorizer") {
			return getEnv("GQLIZER_ENDPOINT");
		}
		return (
			window.PluginsManager.Plugins.find((plugin) => {
				// console.log({ plugin, plugin_name });
				return plugin.name == plugin_name;
			})?.url || null
		);
	}
});

Object.defineProperty(window.PluginsManager, "getBase", {
	value: function () {
		return getEnv("GQLIZER_ENDPOINT");
	}
});

Object.defineProperty(window.PluginsManager, "endpoint", {
	value: function (plugin_name, endpoint, ...path) {
		return `${window.PluginsManager.getEndpoint(plugin_name)}${endpoint}${path.join("")}`;
	}
});

export const PLUGIN_ENDPOINTS = {
	attachamizer: {
		uploads: plugin("attachamizer", "ments/"),
		preview: plugin("attachamizer", "ments/", "previews/"),
		makeUpload: plugin("attachamizer", "")
	},
	authorizer: {
		default: plugin("authorizer", "/api/v1/core/plugins/authorizer/"),
		login: plugin("authorizer", "/api/v1/core/plugins/authorizer/", "login")
	}
};

function cstr(...strs: string[]) {
	return strs.join("");
}

export const hitPlugin = {
	attachamizer: {
		uploads: (endpoint) => cstr(PLUGIN_ENDPOINTS.attachamizer.uploads, endpoint),
		preview: (endpoint) => cstr(PLUGIN_ENDPOINTS.attachamizer.preview, endpoint),
		makeUpload: (endpoint) => cstr(PLUGIN_ENDPOINTS.attachamizer.makeUpload, endpoint)
	},
	authorizer: {
		default: (endpoint) => cstr(PLUGIN_ENDPOINTS.authorizer.default, endpoint),
		login: (endpoint) => cstr(PLUGIN_ENDPOINTS.authorizer.login, endpoint)
	}
};

interface PluginsManagerType {
	Plugins: TschemaData_plugin_manager[];
	getEndpoint(plugin_name: string): string | null;
	getBase(): string;
	endpoint(plugin_name: string, endpoint: string, ...path: string[]): string;
}

declare global {
	interface Window {
		PluginsManager: PluginsManagerType;
	}

	interface globalThis {
		PluginsManager: PluginsManagerType;
	}
}
