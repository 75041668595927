import flatpickr from "flatpickr";
import type { Instance } from "flatpickr/dist/types/instance";
import type { BaseOptions } from "flatpickr/dist/types/options";
import ContextList from "src/components/ContextList.svelte";
import { SvelteComponent } from "svelte/internal";
import { dayjs, notify } from ".";
import { Collapse } from "bootstrap";
import copy, { Options as copyOptions } from "copy-text-to-clipboard";

export function actionFlatpickr(
	node: HTMLInputElement,
	config?: {
		mounted?: (instance: Instance) => void;
		value?: string;
		offset?: number;
	} & Partial<BaseOptions>
) {
	const _config: Partial<BaseOptions> = {
		...(config.value ? { defaultDate: config.value } : {}),
		...(config || {})
	};

	if (!!config.offset && !!config.formatDate) {
		console.warn("Watch out: `config.formatDate` will be overwritten to use the offset");
	}

	if (!!config.offset) {
		_config.formatDate = function (date, format, locale) {
			console.log({ date, format, locale });
			return dayjs(date).add(config.offset, "second").format(format);
		};
	}

	const instance = flatpickr(node, _config);

	if (!!config && typeof config.mounted === "function") {
		config.mounted(instance);
	}

	if (config.value) {
		instance.setDate(config.value);
	}

	return {
		update(params) {
			if (params.value) {
				// console.log(params.value, instance)

				const isTimeOnly = instance.config.noCalendar && instance.config.enableTime;

				instance.setDate(isTimeOnly ? params.value : lib.dayjs(params.value).toDate(), !!instance?.values?.[0]);
			} else {
				/* 				console.warn(
					"Watch out: 'value' not set, expect flatpickr to default to today's date."
				); */
			}
			for (const key in lib.matchType(params, "object", {})) {
				if (key === "value") continue;
				if (instance["config"][key] == params[key]) continue;
				// console.log("setting key", key, params[key], instance["config"][key]);
				instance.set(key, params[key]);
			}
		},
		destroy() {
			if (typeof instance.destroy === "function") {
				instance?.destroy();
			}
		}
	};
}

export function actionSelectTextOnFocus(node) {
	const handleFocus = (event) => {
		node && typeof node.select === "function" && node.select();
	};

	node.addEventListener("focus", handleFocus);

	return {
		destroy() {
			node.removeEventListener("focus", handleFocus);
		}
	};
}

export function actionBlurOnEscape(node) {
	const handleKey = (event) => {
		if (event.key === "Escape" && node && typeof node.blur === "function") node.blur();
	};

	node.addEventListener("keydown", handleKey);

	return {
		destroy() {
			node.removeEventListener("keydown", handleKey);
		}
	};
}

export function actionContextify(
	node: HTMLElement,
	config:
		| {
				config: {
					text?: string;
					action?: () => void;
					seperator?: false;
				}[];
		  }
		| false
) {
	if (config === false) return { destroy() {} };

	const el = document.createElement("div");

	let comp: SvelteComponent;

	function open(e /** for some reason, type Event does not have clientX/Y */) {
		e.preventDefault();

		document.body.appendChild(el);

		comp = new ContextList({
			target: el,
			props: {
				x: e.clientX,
				y: e.clientY,
				...config
			}
		});

		const ctx = el.children[0] as HTMLDivElement; // this grabs the actual component instead of the parent it rendered in
		ctx.tabIndex = 1; // required in order to be focusable
		ctx.focus();
		ctx.onblur = destroyer;
	}

	node.addEventListener("contextmenu", open);

	function destroyer() {
		comp.$destroy();
		el.remove();
	}

	return {
		destroy() {
			try {
				node.removeEventListener("contextmenu", open);
				destroyer();
			} catch (error) {
				// destroyer must of been already called
			}
		}
	};
}

function observe(element, event, handler): () => void {
	element.addEventListener(event, handler, false);
	return () => element.removeEventListener(event, handler, false);
}

export function actionAutoResizeTextarea(node: HTMLTextAreaElement) {
	if (node.type === "textarea") {
		function resize() {
			node.style.height = "auto";
			node.style.height = node.scrollHeight + "px";
		}

		function delayedResize() {
			setTimeout(resize);
		}

		const observers = [
			observe(node, "change", resize),
			observe(node, "cut", delayedResize),
			observe(node, "paste", delayedResize),
			observe(node, "drop", delayedResize),
			observe(node, "keydown", delayedResize)
		];

		return {
			destroy() {
				for (const destroyer of observers) {
					destroyer();
				}
			}
		};
	} else {
		console.error('"autoResizeTextarea" was attempted to get initiated on a non "textarea" element');
		return {};
	}
}

export function actionForbidCharacters(node: HTMLInputElement | HTMLTextAreaElement, ...replacer: string[] | RegExp[]) {
	console.log({ node, replacer });
	function handler() {
		for (const rep of replacer) {
			const oldVal = node.value;
			const newVal = node.value.replace(rep, " ");
			if (oldVal !== newVal) {
				// @ts-ignore
				notify(`Forbidden character: ${rep.toString()}`);
				node.value = newVal;
			}
		}
	}

	const observer = observe(node, "change", handler);

	return {
		destroy() {
			observer();
		}
	};
}

export function actionTabulator(node: HTMLDivElement, config: actionTabulatorOptions) {
	return {};
}

export function actionChecked(node: HTMLInputElement, checked: boolean) {
	function check(checked) {
		if (checked) {
			node.setAttribute("checked", "true");
		} else {
			node.removeAttribute("checked");
		}
	}

	check(checked);
	return {
		update(newChecked) {
			check(newChecked);
		}
	};
}

export function actionResizeObserver(node: HTMLDivElement, cb: () => void) {
	const observer = new ResizeObserver(cb);
	observer.observe(node);
	return {
		destroy() {
			observer.disconnect();
		}
	};
}

export function actionUpload(
	node: HTMLFormElement,
	config: {
		tableName?: string;
		id?: string;
		hidden?: boolean;
		field?: string;
		callback?: (id: number) => void;
	}
) {
	upload({ node, ...config });

	return {};
}
