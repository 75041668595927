<script lang="ts">import { onDestroy, onMount } from "svelte";
export let config = [];
export let x = 0;
export let y = 0;
// export let self: SvelteComponent = undefined;
export let destroyer = undefined;
const initPosX = window.scrollX;
const initPosY = window.scrollY;
let scrollX = 0;
let scrollY = 0;
$: style = `
	z-index: ${1e3};
	--context-list-x: ${+x + initPosX - scrollX}px;
	--context-list-y: ${+y + initPosY - scrollY}px;`;
let contextMenu;
$: destroyerProxy = function () {
    destroyer();
};
onMount(function () {
    window.addEventListener("click", destroyerProxy);
});
onDestroy(function () {
    window.removeEventListener("click", destroyerProxy);
});
</script>

<svelte:window bind:scrollX bind:scrollY on:focus={destroyer} />

<div bind:this={contextMenu} id="context-list" {style} class="border rounded shadow">
	<ul>
		{#each config as item}
			{#if item.seperator}
				<li>-</li>
			{:else}
				<li on:mousedown={item.action}>
					{item.text}
				</li>
			{/if}
		{/each}
	</ul>
</div>

<style>
	#context-list {
		position: fixed;
		left: var(--context-list-x);
		top: var(--context-list-y);
		background: white;
		outline: none;
	}

</style>
