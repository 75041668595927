import { cloneDeep } from "lodash";
import { APP_CONFIG } from "src/lib";
import { TabulatorFull } from "tabulator-tables";
import RecordSelector from "./RecordSelector.svelte";
import flatpickr from "flatpickr";

export function getColumnDef(
	fieldDef: Schema.FieldDefinition<Schema.FieldDefinition_Type, any, any> & {
		field: string;
	},
	tableName: TschemaTableName
): Tabulator.ColumnDefinition {
	const out: ReturnType<typeof getColumnDef> = {
		title: fieldDef.niceName || fieldDef.field,
		field: fieldDef.field
	};

	const editor = (
		{
			boolean: "tickCross",
			date: "input",
			time: "input",
			date_time: "input",
			enum: "select",
			float: "number",
			integer: "number",
			string: "input",
			text: "input",
			foreign: "input"
		} as { [key in Schema.FieldDefinition_Type]: Tabulator.Editor }
	)[fieldDef.type];

	out.headerFilter = editor;
	out.headerFilterParams ||= {};

	out.editor = editor;
	out.editorParams ||= {};

	if (["link"].includes(fieldDef.type) || fieldDef.group_name === "Defaults") {
		delete out.editor;
	}

	if (fieldDef.type === "foreign") {
		out.editor = function (cell, onRendered, success, cancel, editorParams) {
			const el = document.createElement("div");
			lib.dialog({
				component: RecordSelector,
				props: {
					tableName: fieldDef.foreign.table,
					buttons: [
						{
							label: "Cancel",
							action() {
								cancel();
							}
						}
					],
					cb(data) {
						console.log(data);
						success([data]);
					}
				}
			});
			el.innerText = "Selection in progress";
			return el;
		};

		out.formatter = function (cell) {
			return cell.getValue()?.[0]
				? lib.convertTemplate(lib.getDefaultFormatter(fieldDef.foreign.table), cell.getValue()[0])
				: "";
		};

		out.headerFilterFunc = function (headerValue, rowValue, rowData, filterParams) {
			const str = rowValue?.[0] ? lib.convertTemplate(lib.getDefaultFormatter(fieldDef.foreign.table), rowValue[0]) : "";

			if (!str) {
				return false;
			}

			return lib.tryOr(() => Array.isNotEmpty(lib.filterStrings(headerValue, [str])), false);
		};
	}

	if (fieldDef.type === "link") {
		out.formatter = function (cell, formatterParams, onRendered) {
			const div = document.createElement("div");
			div.innerText = ((cell.getValue() as { id: string }[]) || [])
				.map((val) => lib.convertTemplate(lib.getDefaultFormatter(fieldDef.link.table), val))
				.join();

			if (!cell.getRow().getData()["id"]) {
				onRendered(() => {
					div.parentElement.style.background = "lightgrey";
				});
			}

			return div;
		};

		out.editor = function (cell, onRendered, success, cancel, editorParams) {
			if (!cell.getRow().getData()["id"]) {
				return cancel();
			}
			const el = document.createElement("div");
			const selected = (cell.getValue() || []).mapFromObjectKey("id");
			const dialog = lib.dialog({
				component: RecordSelector,
				props: {
					tableName: fieldDef.link.table,
					link:
						fieldDef.type === "link"
							? {
									selected,
									origin: tableName,
									origin_id: cell.getData().id,
									foreign: fieldDef.field,
									processColumn: ["acl", "rcl"].includes(tableName)
										? (col) => {
												if (col.field === "table") {
													col.visible = false;
												}
										  }
										: _.noop
							  }
							: undefined,
					buttons: [
						{
							label: "Close",
							action(ctx) {
								cancel();
								// console.log(ctx.getData());
								cell.setValue(ctx.getData().filter(({ id }) => selected.includes(id)));
							}
						}
						// {
						// 	label: "Set",
						// 	action(ctx) {
						// 		// console.log(ctx.getSelectedRows());
						// 	}
						// }
					],
					...(["acl", "rcl"].includes(tableName) && ["read", "update"].includes(fieldDef.field)
						? {
								where: {
									table: { name: { eq: cell.getData().table[0].name } }
								}
						  }
						: {})
				}
			});
			dialog.then((comp) => {
				comp.$$.on_destroy.push(cancel);
			});
			el.innerText = "Selection in progress";
			return el;
		};

		out.headerFilter = "input";

		out.headerFilterFunc = function (headerValue, rowValue, rowData, filterParams) {
			const strs = (rowValue || []).map((rowValue) =>
				lib.convertTemplate(lib.getDefaultFormatter(fieldDef.link.table), rowValue)
			);

			return Array.isNotEmpty(lib.filterStrings(headerValue, strs));
		};
	}

	if (fieldDef.type === "enum") {
		// console.log(fieldDef);
		out.headerFilterParams.values = fieldDef.enum.values;
		out.editorParams.values = fieldDef.enum.values;
	}

	if (fieldDef.type === "date") {
		out.headerFilter = function (cell, onRendered, success, cancel, editorParams) {
			const button = document.createElement("button");
			button.classList.add("btn");
			button.classList.add("border");
			button.innerText = "Date";
			let last_selected;
			const instance = flatpickr(button, {
				onChange(selectedDates) {
					const date = selectedDates[0];
					if (date) {
						success(lib.dayjs(date).format("YYYY-MM-DD"));
						last_selected = lib.dayjs(date).format("YYYY-MM-DD");
						button.innerText = lib.dayjs(date).format("YYYY-MM-DD");
					} else {
						success();
						button.innerText = "Select Date";
					}
				},
				onClose(selectedDates) {
					if (!selectedDates.length) {
						success();
						button.innerText = "Select Date";
					}
					if (lib.dayjs(selectedDates[0]).format("YYYY-MM-DD") === last_selected) {
						success();
						button.innerText = "Select Date";
					}
				}
			});
			button.onclick = () => {
				instance.open();
			};
			return button;
		};
	}

	return out;
}

export function getColumns(args: { tableName: TschemaTableName; editable?: boolean }): Tabulator.ColumnDefinition[] {
	const { tableName, editable = true } = args;
	const out: ReturnType<typeof getColumns> = [
		{
			title: "",
			formatter: "rowSelection",
			titleFormatter: "rowSelection",
			hozAlign: "center",
			headerSort: false
		}
	];

	const groups: {
		[key: string]: ({ field: string } & Schema.FieldDefinition<any, any, any>)[];
	} = {};

	Object.entries(lib.schema[tableName]).forEach(([field, fieldDef]) => {
		if (!groups[fieldDef.group_name]) {
			groups[fieldDef.group_name] = [];
		}

		if (!fieldDef.reverse) {
			groups[fieldDef.group_name].push({
				...fieldDef,
				field
			});
		}
	});

	const groupedOrder = [
		"Defaults",
		...Object.keys(groups).filter((groupKey) => !["", "Defaults"].includes(groupKey)),
		""
	];

	for (const groupName of groupedOrder) {
		out.push({
			title: groupName,
			columns: groups[groupName].map((def) => getColumnDef(def, tableName))
		});
	}

	if (!editable) {
		lib.walk(out, {
			ignoreKeys: [],
			ignoreType: [],
			ignoreTypeOf: [],
			entry({ obj }) {
				if (lib.isObject(obj) && !!obj.editor) {
					delete obj.editor;
				}
			}
		});
	}

	return out;
}

export async function setupTabulator(args: {
	el: HTMLElement;
	setChanged?(changed: boolean): void;
	setOriginalData?(originalData: any[]): void;
	tableName: TschemaTableName;
	editable?: boolean;
	mod?(conf: Tabulator.Options): Tabulator.Options;
	recordChanged?(row: Tabulator.RowComponent): void;
	where?: any;
}): Promise<TabulatorFull> {
	const {
		el,
		setChanged = lib.xf.noop,
		setOriginalData = lib.xf.noop,
		tableName,
		editable = true,
		mod = lib.xf.selfop,
		recordChanged = lib.xf.noop,
		where
	} = args;

	const columns = getColumns({ tableName, editable });

	function postProcessCols(cols: Tabulator.ColumnDefinition[]) {
		for (const col of cols) {
			if (col.columns) {
				postProcessCols(col.columns);
			}
			if (col.editor === "tickCross" && !col.formatter) {
				col.formatter = function (cell) {
					if (!cell.getElement().onmousedown) {
						cell.getElement().onmousedown = (e) => {
							cell.setValue(!cell.getValue());
						};
					}
					const el = document.createElement("input");
					el.type = "checkbox";
					el.checked = cell.getValue();
					return el;
				};
				delete col.editor;
			}
			if (["updated_at", "updated_by", "created_at", "created_by"].includes(col.field)) {
				// col.visible = false;
			}
			col.maxWidth = 400;
		}
	}

	postProcessCols(columns);

	const instance = new Tabulator(
		el,
		mod({
			columns: columns.filter((groupCol) => groupCol.formatter === "rowSelection" || Array.isNotEmpty(groupCol.columns)),
			paginationSize: 15,
			paginationSizeSelector: [10, 15, 25, 50, 100],
			paginationCounter: "rows",
			rowContextMenu: [
				{
					label: "Delete",
					async action(e, row) {
						function deleteRecords(rows) {
							lib.confirmDialog(
								() => {
									lib
										.remove({ tableName, id: rows.map((row) => row.getData().id) })
										.then(() => {
											rows.forEach((row) => {
												row.delete();
											});
										})
										.catch(console.error);
								},
								_.noop,
								`Delete ${rows.length} records?`
							);
						}
						if (row.getTable().getSelectedRows().length > 0) {
							deleteRecords(row.getTable().getSelectedRows());
						} else {
							deleteRecords([row]);
						}
					}
				}
			].if(editable),
			ajaxFiltering: true,
			filterMode: "remote",
			ajaxSorting: true,
			pagination: true,
			paginationMode: "remote",
			ajaxURL: "data",
			sortMode: "remote",
			ajaxRequestFunc(url, params, { filter: filters, page, size, sort: [sort] }) {
				const where: any[] = ["AND"];

				if (args.where) {
					where.push(args.where);
				}

				for (const filter of filters) {
					const def = lib.schema[tableName][filter.field];

					if (["foreign", "link"].includes(def.type)) {
						const fields = Array.from(
							String(lib.getDefaultFormatter(def[def.type].table)).match(
								new RegExp(`${"\\" + String.fromCharCode(36)}${":"}[a-z|A-Z|0-9|\.]+`, "g")
							) || []
						)
							.map((field) => field.replace("$:", ""))
							.filter((field) => field in lib.schema[def[def.type].table]);

						if (Array.isNotEmpty(fields)) {
							const out: any[] = ["OR"];

							for (const field of fields) {
								const compare_type = {
									boolean: "eq",
									date: "eq",
									date_time: "eq",
									enum: "eq",
									float: "eq",
									foreign: "eq",
									integer: "eq",
									string: "like",
									text: "like",
									time: "like"
								}[lib.schema[def[def.type].table][field].type];

								out.push(
									lib.emptyObjectFromString(
										[def.field, field, compare_type].join(".").split(".").filter(isNaN).join("."),
										compare_type === "like" ? `%${filter.value}%` : String(filter.value)
									)
								);
							}

							where.push(out);
						} else {
							console.log(fields);
						}
						// } else if (def.type === "link") {
					} else {
						where.push(
							lib.emptyObjectFromString(
								[filter.field, { like: "like" }[filter.type] || "eq"].join(".").split(".").filter(isNaN).join("."),
								filter.type === "like" ? `%${filter.value}%` : String(filter.value)
							)
						);
					}
				}

				return new Promise(async function (resolve, reject) {
					const config = {
						tableName,
						alias: "res",
						pagination: {
							limit: size,
							offset: (page - 1) * size
						},
						where,
						fields: Object.keys(lib.schema[tableName]).map((field) => {
							const fieldDef = lib.schema[tableName][field];
							if (fieldDef.reverse) {
								return "";
							}
							if (!["foreign", "link"].includes(fieldDef.type)) {
								return field;
							} else {
								return `${field} {${[
									"id",
									lib.CONVERT_REGEX.execAll(lib.getDefaultFormatter(lib.getForeignTableName(tableName, field))).map((res) =>
										res[0].replace("$:", "")
									)
								]
									.flat(2)
									.unique()
									.join("\n")}}`;
							}
						})
					};

					if (sort) {
						config.pagination.order = sort.dir.toUpperCase();
						config.pagination.order_by = sort.field;
					}

					const { res: data } = await lib.get2(config);
					const count = await lib.get2Count(_.omit(config, ["pagination"]));

					const last_page = _.attempt(() => Math.ceil(+count / instance.getPageSize()));

					if (last_page instanceof Error) {
						reject(last_page);
					}

					resolve({ last_page, data: data || [] });
				});
			}
		} as Tabulator.Options)
	);

	instance.on("cellEdited", function (cell: Tabulator.CellComponent) {
		recordChanged(cell.getRow());
		setChanged(true);
		instance.rowManager.adjustTableSize();
	});

	instance.on("rowAdded", function () {
		instance.rowManager.adjustTableSize();
		setChanged(true);
	});

	instance.on("rowDeleted", function () {
		instance.rowManager.adjustTableSize();
		setChanged(true);
	});

	instance.on("cellEditing", function () {
		instance.rowManager.adjustTableSize();
	});

	return instance;
}
