import axios from "axios";
import { APP_CONFIG, get2, getEnv, notify, query, xf } from "src/lib";
import { INTER_STORE, uploads } from "src/store";
import { get_store_value } from "svelte/internal";

export function makeUpload(config: {
	form: FormData;
	tableName?: string;
	id?: string;
	hidden?: boolean;
	callback?: (id: number, data: TschemaData_Attachment) => void;
	field?: string;
}) {
	const { form } = config;

	config.field ||= "Attachment";

	if (!form.has("files")) {
		throw new Error("No file has been selected");
	}

	const id = xf.random();

	uploads.update((state) => {
		state[id] = {
			// @ts-ignore
			file: form.get("files"),
			progress: 0
		};
		return state;
	});

	axios
		.post(lib.getStoreValue(APP_CONFIG).attachamizer.upload, form, {
			headers: { "Content-Type": "multipart/form-data" },
			onUploadProgress(ev) {
				uploads.update(function (state) {
					state[id].progress = (ev.loaded / ev.total) * 100;
					return state;
				});
				if (get_store_value(uploads)[id].progress === 100) {
					setTimeout(function () {
						uploads.set(xf.Object(get_store_value(uploads)).burst(id));
					}, 1000);
				}
			}
		})
		.then(
			async ({
				data: {
					data: [attach]
				}
			}) => {
				const { id: attach_id } = attach;
				if (config.tableName && config.id) {
					await linkUpload({
						tableName: config.tableName,
						id: config.id,
						Attachment: attach_id,
						field: config.field
					});
				}
				typeof config.callback === "function" && config.callback(+attach_id, attach);
				notify("Success");
			}
		)
		.catch((err) => {
			console.error(err);
			notify("Failed");
		});
}

export async function linkUpload(config: {
	tableName: TschemaTableName;
	id: string;
	Attachment: string;
	field?: string;
}) {
	const {
		res: [{ attach }]
	} = await get2({
		tableName: config.tableName as TschemaTableName,
		where: { id: { eq: String(config.id) } },
		alias: "res",
		fields: [`attach: ${config.field} { id }`]
	});
	return await query(`mutation {
		update_${config.tableName}(
			where: { id: { eq: "${config.id}" } }
			data: { ${config.field}: [${[...new Set([config.Attachment, ...(attach || [])].map((id) => +id || +id.id))].join(
		","
	)}] }
		) {
			id
		}
	}`);
}

type magicalUploadNode = HTMLFormElement & {
	openSelector?(): void;
};

function generateUploadNode(options: Partial<magicalUploadNode> = {}): magicalUploadNode {
	const id = "manualAttachmentUpload";

	const existing = document.getElementById(id);

	if (existing) {
		existing.remove();
	}

	const newNode = document.createElement("form");

	newNode.enctype = "multipart/form-data";
	newNode.method = "post";
	newNode.action = lib.PLUGIN_ENDPOINTS.attachamizer.default;
	newNode.multiple = false;

	for (const key in options) {
		newNode[key] = options[key];
	}

	return newNode;
}

function inputifyNode(node: magicalUploadNode, onUpload: (e?: Event) => void) {
	const inputFile = document.createElement("input");

	inputFile.type = "file";
	inputFile.name = "files";
	inputFile.onchange = function (e) {
		onUpload(e);
	};

	node.appendChild(inputFile);

	node.openSelector = function () {
		inputFile.click();
	};
}

export function upload(config: {
	node?: HTMLFormElement;
	tableName?: string;
	id?: string;
	hidden?: boolean;
	field?: string;
	callback?: (id: number, data: TschemaData_Attachment) => void;
}) {
	if (!config.node) {
		config.node = generateUploadNode();
		config.hidden = true;
	}

	function submit(e?: Event) {
		if (e && e.preventDefault) {
			e.preventDefault();
		}

		const form = new FormData(config.node);

		makeUpload({
			...config,
			form
		});
	}

	if (config.hidden) {
		config.node.style.display = "none";
		inputifyNode(config.node, submit);
	} else {
		config.node.addEventListener("submit", submit);
	}

	return config.node;
}

export function uploadGetContents(
	config: {
		uploadNode?: Partial<magicalUploadNode>;
		modifyRes?: (res: any) => any;
		through?: "inter_store" | "localStorage";
	} = {}
): Promise<string> {
	return new Promise(function (resolve, reject) {
		const node = generateUploadNode(config.uploadNode || {});

		inputifyNode(node, function (e) {
			let reader = new FileReader();

			reader.onload = ({ currentTarget: { result: res } }) => {
				if (config.through === "localStorage") {
					const localStorageUid = lib.tacticalUid();
					localStorage[localStorageUid] = res;
					resolve(localStorageUid);
				} else {
					resolve(INTER_STORE.set((config?.modifyRes || lib.xf.selfop)(res)));
				}
			};

			// Read in the image file as a data URL.
			reader.readAsText(e.target.files[0]);
		});

		node.openSelector();
	});
}
