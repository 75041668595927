import { pascalCase } from "change-case";

Object.defineProperties(Array.prototype, {
	findAll: {
		value: function (callback) {
			const results = [];
			for (const value of this) {
				if (callback(value)) results.push(value);
			}
			return results;
		}
	},
	findAllAndTake: {
		value: function (this: any[], callback) {
			const toSplice = [];
			this.forEach((v, i, a) => {
				if (callback(v, i, a)) {
					toSplice.push(i);
				}
			});
			let spliced = 0;
			const out = [];
			for (const toSpliceIndex of toSplice) {
				out.push(...this.splice(toSpliceIndex - spliced, 1));
				spliced++;
			}
			return out;
		}
	},
	findByField: {
		value: function (field: string, value: string | number | boolean) {
			const out = [];
			for (const rec of this) {
				if (rec[field] == value) {
					out.push(rec);
				}
			}
			if (out.length > 1) {
				throw `"${field}" is not a unique field`;
			}
			return out[0] || null;
		}
	},
	findIndexByField: {
		value: function (field: string, value: string | number | boolean) {
			const out = [];
			for (const index in this) {
				if (this[index][field] == value) {
					out.push(index);
				}
			}
			if (out.length > 1) {
				throw `"${field}" is not a unique field`;
			}
			// console.log(out);
			return out[0] || null;
		}
	},
	asyncForEach: {
		value: async function <T = any>(
			this: T,
			callback: (value: T[keyof T], index: number, array: T) => Promise<void> | void
		) {
			for (const index in this) {
				await callback(this[index], +index, this);
			}
			return;
		}
	},
	asyncMap: {
		value: async function <N = any, T = any>(
			this: T,
			callback: (value: T[keyof T], index: number, array: T) => N
		): Promise<N[]> {
			const out = [];
			for (const index in this) {
				const value = this[index];
				await out.push(await callback(value, +index, this));
			}
			return out;
		}
	},
	insert: {
		value: function (index, item) {
			this.splice(index, 0, item);
		}
	},
	if: {
		value: function (condition) {
			return condition ? this : [];
		}
	},
	toggle: {
		value: function (value) {
			const allowedValues = ["boolean", "number", "string"];
			if (!allowedValues.includes(typeof value)) {
				throw new Error(`typeof value ${typeof value} is not a ${allowedValues.join(" or ")}`);
			} else {
				if (this.includes(value)) {
					var index = this.indexOf(value);
					if (index !== -1) {
						this.splice(index, 1);
					}
				} else {
					this.push(value);
				}
			}
		}
	},
	mapToObject: {
		value: function (cb) {
			const out = {};
			for (const itemIndex in this) {
				const item = this[itemIndex];
				const [key, obj] = cb(item, itemIndex, this);
				out[key] = obj;
			}
			return out;
		}
	},
	mapFromObjectKey: {
		value: function (key) {
			// @ts-ignore
			return this.map(({ [key]: v }) => v);
		}
	},
	removeAt: {
		value: function (index) {
			return this.splice(index, 1);
		}
	},
	remove: {
		value: function (el) {
			const removed = [];
			for (const index in this) {
				if (this[index] == el) {
					removed.push(...this.removeAt(index));
				}
			}
			return removed;
		}
	},
	filterExcludeValues: {
		value: function (values: any[]) {
			return this.filter((v) => !values.includes(v));
		}
	},
	byUniqueObjectProperty: {
		value: function (key) {
			return [...new Map(this.map((item) => [item[key], item])).values()];
		}
	},
	add: {
		value: function (obj) {
			this.push(obj);
		}
	},
	unique: {
		value: function () {
			return [...new Set(this)];
		}
	},
	swap: {
		value: function (i1, i2) {
			if (typeof i1 === "number" && typeof i2 === "number") {
				if (this.length > Math.max(i1, i2) && -1 < Math.min(i1, i2)) {
					[this[i1], this[i2]] = [this[i2], this[i1]];
				}
			}
		}
	},
	steal: {
		value: function (item) {
			if (this.includes(item)) {
				this.remove(item);
				return item;
			}
		}
	},
	undefinedIfEmpty: {
		value: function () {
			return this.length ? this : undefined;
		}
	}
});

Object.defineProperties(String.prototype, {
	in: {
		value: function (arr: string[]) {
			return arr.includes(this);
		}
	},
	notIn: {
		value: function (arr: string[]) {
			return !arr.includes(this);
		}
	},
	if: {
		value: function (condition: boolean | (() => boolean)) {
			return (typeof condition === "function" ? condition.call(this) : condition) ? this.toString() : "";
		}
	},
	pretty: {
		get() {
			return this.split(/_| |-/)
				.map((v) => pascalCase(v))
				.join(" ");
		}
	},
	wrap: {
		value: function (before, after) {
			return typeof before === "function" ? before(this.toString()) : before + this.toString() + after;
		}
	},
	wrapWithHtmlTag: {
		value: function (tag: keyof HTMLElementTagNameMap, attributes = {}) {
			const out = document.createElement(tag);
			out.innerHTML = this.toString();
			for (const attr in attributes) {
				out.setAttribute(attr, attributes[attr]);
			}
			return out.outerHTML; //`<${tag}>${this.toString()}</${tag}>`;
		}
	},
	easyHash: {
		value: function (this: string) {
			const size = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

			let counter = 0;
			for (const charIndex in this as unknown as string[]) {
				size[counter] += this.charCodeAt(+charIndex);

				if (size[counter] > 255) {
					size[counter] = 0;
				}

				if (counter == 9) {
					counter = 0;
				} else {
					counter++;
				}
			}

			return size
				.map((s) => s.toString(16))
				.map((s) => (s.length < 2 ? `0${s}` : s))
				.join("");
		}
	}
});

Object.defineProperties(Map.prototype, {
	inverted: {
		get() {
			return new Map(Array.from(this, (a: any) => a.reverse()));
		}
	},
	toObject: {
		value() {
			return Array.from(this).reduce((p, [key, value]) => {
				if (typeof key === "string") {
					p[key] = value;
				}
				return p;
			}, {});
		}
	}
});

Object.defineProperties(JSON, {
	pretty: {
		value: function (obj) {
			return JSON.stringify(obj, null, 4);
		}
	},
	same: {
		value: function (a, b) {
			return JSON.stringify(a) === JSON.stringify(b);
		}
	}
});

Object.defineProperties(Array, {
	isEmpty: {
		value: function (arr) {
			return Array.isArray(arr) && arr.length == 0;
		}
	},
	isNotEmpty: {
		value: function (arr) {
			return Array.isArray(arr) && arr.length > 0;
		}
	},
	isNotArray: {
		value: function (arr) {
			return !Array.isArray(arr);
		}
	},
	isIndexedObject: {
		value: function (obj) {
			let valid = true;
			let order = 0;
			for (const key in obj) {
				if (Number(key) == order) {
					order++;
				} else {
					valid = false;
					break;
				}
			}

			return valid;
		}
	},
	fromIndexedObject: {
		value: function (obj) {
			if (!Array.isIndexedObject(obj)) {
				console.error(obj);
				throw "INVALID OBJ";
			} else {
				const out = [];
				for (const key in obj) {
					out.push(obj[key]);
				}
				return out;
			}
		}
	},
	ensure: {
		value: function (arr) {
			return Array.isArray(arr) ? arr : [];
		}
	}
});

Object.defineProperties(RegExp.prototype, {
	execAll: {
		value: function (this: RegExp, str: string) {
			if (!this.global) {
				throw "NOT GLOBAL";
			}
			const out = [];
			while (true) {
				const toPush = this.exec(str);
				if (toPush) {
					out.push(toPush);
				} else {
					break;
				}
			}
			return out;
		}
	}
});

Object.defineProperties(Set.prototype, {
	toArray: {
		value: function (this: Set<any>) {
			return Array.from(this);
		}
	}
});

Object.defineProperties(FileList.prototype, {
	toJSON: {
		value: function (this: FileList) {
			return Array.from(this);
		}
	}
});

Object.defineProperties(File.prototype, {
	toJSON: {
		value: function (this: File) {
			const out = {};
			for (const key in this) {
				if (key === "base64") {
					out[key] = 'Actual data is stored in memory'
				} else if (typeof this[key] !== "function") {
					out[key] = this[key];
				}
			}
			return out;
		}
	}
});

declare global {
	interface String {
		if(condition: boolean | (() => boolean)): string;
		pretty: string;
		wrap(before: string, after: string): string;
		wrap(template: (str: string) => string): string;
		wrapWithHtmlTag(tag: keyof HTMLElementTagNameMap, attributes?: stringOfstring): string;
		easyHash(): string;
	}

	interface Array<T> {
		asyncMap<N = any, T = any>(this: T, callback: (value: T[keyof T], index: number, array: T) => N): Promise<N[]>;
		asyncForEach(this: T, callback: (value: T[keyof T], index: number, array: T) => Promise<void> | void): Promise<void>;
		insert(index: number, item: T): void;
		if(condition?: any): T[] | [];
		toggle: T extends boolean | number | string ? (value: T) => void : never;
		mapToObject<P extends string | number | symbol, O>(
			cb: (value: T, index: keyof T[], arr: T[]) => [P, O]
		): { [key in P]: O };
		mapFromObjectKey<K extends keyof T>(key: K): T[K][];
		add(item: T): void;
		removeAt(index: number): [T];
		remove(item: T): T[];
		filterExcludeValues(values: T[]): T[];
		byUniqueObjectProperty(key: keyof T): T[];
		unique(): T[];
		swap(i1: number, i2: number): void;
		steal(item: T): T | void;
		undefinedIfEmpty(): T[] | undefined;
	}

	interface ArrayConstructor {
		isNotArray(arr: any): boolean;
		isEmpty(arr: any): boolean;
		isNotEmpty(arr: any): boolean;
		isIndexedObject(obj: any): boolean;
		fromIndexedObject<T = any>(obj: { [key: number]: T }): T[];
		ensure<T>(arr: T[]): T[];
	}

	interface Map<K, V> {
		inverted: Map<V, K>;
		toObject(): { [key in keyof K]: V };
	}

	interface JSON {
		pretty(obj: any): string;
		same(a: any, b: any): boolean;
	}

	interface RegExp {
		execAll(str): RegExpMatchArray[];
	}

	interface Set<T> {
		toArray(): T[];
	}

	interface FileList {
		toJSON(): File[];
	}

	interface File {
		base64?: string;
		toJSON(): { [key in keyof File]: File[key] };
	}
}
