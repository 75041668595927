<script lang="ts">export let header = "No Header or slot header";
export let bodyClass = "";
export let bodyStyle = "";
export let headerClass = "";
</script>

<div class="card card-body-no-padding">
	<div class="card-header {headerClass} pt-0 pb-0">
		<slot name="header">{header}</slot>
	</div>
	<div class="card-body {bodyClass}" style={bodyStyle}>
		<slot />
	</div>
</div>

<style lang="scss">.card-body-no-padding > .card-body {
  padding-right: 0px;
  padding-bottom: 0px;
}</style>
