<script lang="ts">export let options = [];
export let x, y;
export let destroy = svelte.internal.noop;
const initPosX = window.scrollX;
const initPosY = window.scrollY;
let scrollX = 0;
let scrollY = 0;
$: style = `
	position: absolute;
	z-index: ${2e3};
	--context-list-x: ${+x + initPosX - scrollX}px;
	--context-list-y: ${+y + initPosY - scrollY}px;`;
function hoverStart(e) {
    e.target.classList.add("bg-primary");
    e.target.classList.add("text-light");
}
function hoverEnd(e) {
    e.target.classList.remove("bg-primary");
    e.target.classList.remove("text-light");
}
</script>

<svelte:window bind:scrollX bind:scrollY />

<div {style}>
	<ul class="list-group" id="context-list">
		{#each options as { text, icon, action, disabled }}
			<li
				class="list-group-item"
				class:disabled
				on:mouseenter={hoverStart}
				on:mouseleave={hoverEnd}
				on:mousedown={(e) => {
					action(e);
					destroy();
				}}
			>
				{text}
			</li>
		{/each}
	</ul>
</div>

<style>
	#context-list {
		position: fixed;
		left: var(--context-list-x);
		top: var(--context-list-y);
		background: white;
		outline: none;
	}

</style>
