import { notify, query } from "./";

/** @typedef {{id:string}} remove_config  */

/**
 * @param {remove_config} config ID and TableName
 * @returns {Promise<boolean>} whether record/s were removed or not
 * @method remove
 * @memberof module:lib
 */
export function remove(config: { id: number | number[]; tableName: TschemaTableName }): Promise<boolean> {
	return new Promise((resolve, reject) => {
		let mutation = `mutation delete_${config.tableName}s ($ids: JSON!) {`;
		mutation += `
				delete_${config.tableName}(
					where: ["AND", {
						id: {
							in: $ids
						}
					}])
				}`;
		query(mutation, {
			variables: {
				ids: typeof config.id === "number" ? [config.id] : config.id
			}
		})
			.then((res) => {
				// notify({ text: "Success" });
				console.log("removed", { res });
				resolve(true);
			})
			.catch((err) => {
				console.error(err);
				notify({ text: "Fail" });
				reject(false);
			});
	});
}
