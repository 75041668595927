if (typeof window !== "undefined") {
	const evs = {
		save: new Event("save"),
		saveAs: new Event("saveAs")
	};

	window.addEventListener("keydown", (ev) => {
		if (["s", "S"].includes(ev.key) && ev.ctrlKey) {
			ev.preventDefault();
			ev.stopImmediatePropagation();
			window.dispatchEvent(ev.shiftKey ? evs.saveAs : evs.save);
		}
		if (["Escape"].includes(ev.key)) {
			window.dispatchEvent(new Event("cancel"));
		}
	});
}

const tempEl = document.createElement("div");
Object.assign(tempEl.style, {
	height: "1in",
	width: "1in"
});
document.body.appendChild(tempEl);
globalThis["dpi"] = tempEl.offsetHeight;
tempEl.remove();

if (typeof navigator !== "undefined" && typeof navigator.clipboard === "undefined") {
	// @ts-expect-error
	navigator.clipboard = {
		readText() {
			return new Promise<string>(async function (resolve, reject) {
				const PolyClipboard = (await import("/src/components/PolyClipboard.svelte")).default;

				lib.dialog({
					component: PolyClipboard,
					props: {
						mode: "read",
						cb(text) {
							resolve(text);
						}
					}
				});
			});
		},
		writeText(text: string) {
			return new Promise<void>(async function (resolve, reject) {
				const PolyClipboard = (await import("/src/components/PolyClipboard.svelte")).default;
				lib
					.dialog({
						component: PolyClipboard,
						props: {
							mode: "write",
							text
						}
					})
					.then(() => resolve());
			});
		}
	};
}

function makeGlobal(prop: string, obj: any) {
	if (typeof global !== "undefined" && typeof global[prop] === "undefined") {
		global[prop] = obj;
	}
	if (typeof globalThis !== "undefined" && typeof globalThis[prop] === "undefined") {
		global[prop] = obj;
	}
	if (typeof window !== "undefined" && typeof window[prop] === "undefined") {
		window[prop] = obj;
	}
}

function makeGlobals(propedObjs: { [key: string]: any }) {
	for (const [prop, obj] of Object.entries(propedObjs)) {
		makeGlobal(prop, obj);
	}
}

makeGlobals({ makeGlobal, makeGlobals });

// AsyncFunction
// purpose of it is to use this when we want a asynchrous `new Function()`
// can't believe this isn't in officially in JS
const AsyncFunction = async function () {}.constructor;
makeGlobals({ AsyncFunction });
