// this is for some scripts
window.APP = "SVELTINATOR";

// next is css
import "src/scss/global.scss";
import "src/assets/css/ck-content.scss";
import "flatpickr/dist/flatpickr.min.css";

// import lodash
import _ from "src/lib/lodash";
makeGlobals({ _ });

// import lib
import * as lib from "src/lib";
makeGlobals({ lib });

// svelte
import * as svelte from "svelte";
import type typeOfSvelte from "svelte";
import * as svelteStore from "svelte/store";
import type typeOfSvelteStore from "svelte/store";
import * as svelteInternal from "svelte/internal";
import type typeOfSvelteInternal from "svelte/internal";

makeGlobals({
	svelte: {
		...svelte,
		store: {
			writable_ls_sync(id, initValue = null, startStop = _.selfop(_.noop)) {
				const val = _.attempt(() => JSON.parse(localStorage.getItem(id)));
				// @ts-ignore
				const { subscribe, update, set } = svelte.store.writable(_.isError(val) ? initValue : val);

				return {
					subscribe,
					update,
					set(newVal) {
						// @ts-ignore
						if (svelte.safe_not_equal(lib.getStoreValue({ subscribe }), newVal)) {
							localStorage.setItem(newVal, JSON.stringify(newVal));
							set(newVal);
						}
					}
				};
			},
			...svelteStore
		},
		internal: svelteInternal,
		once: function (expected: () => orArr, callback: () => void) {
			let called = false;

			function isAvailable(debug) {
				let value = expected();
				// if (debug) {
				// 	console.log({ expected, value });
				// }
				if (Array.isArray(value)) {
					return !value.includes(false);
				} else return !!value;
			}

			function call(debug = false) {
				if (!called && isAvailable(debug)) {
					callback();
					called = true;
				}
			}

			svelte.onMount(call);
			svelte.beforeUpdate(call);
			svelte.afterUpdate(call);

			svelte.onDestroy(() => {
				if (!called) {
					if (false) {
						console.log(`Expected values`, expected, `never became available`);
					}
				}
			});

			return call;
		},
		setContexts: function <T>(ctxs: { [key: string | number | symbol]: T }): void {
			for (const key of [...Object.keys(ctxs), ...Object.getOwnPropertySymbols(ctxs)]) {
				svelte.setContext(key, ctxs[key]);
			}
		},
		getContextsObj: () => {
			return Object.fromEntries(svelte.getAllContexts().entries());
		},
		ensureContext: <T>(key: string, value: () => T) => {
			const ctxs = svelte.getAllContexts();
			if (ctxs.has(key)) {
				return ctxs.get(key);
			} else {
				const item = value();
				ctxs.set(key, item);
				return item;
			}
		}
	}
});

// 3rd party
import "share-api-polyfill";
import "default-passive-events";

// events
import EventEmitter from "events";

const EVENTS = new EventEmitter();
makeGlobals({ EVENTS });

// jquery, really didn't want to but needed for some bootstrap
import jq from "jquery";
makeGlobals({
	jQuery: jq,
	$: jq
});
import initBs from "src/lib/bootstrap";
initBs(jq);

// PubSub, not really used anymore
import PubSub from "pubsub-js";
import { DEBUG_APP } from "./store";
PubSub.setDelimiter("/");
PubSub.subscribe("*", (ev, info) => {
	if (lib.getStoreValue(DEBUG_APP)) {
		console.log(ev, info);
	}
});

// dpi px utils
makeGlobals({
	mmToPx: (mm) => (mm / 25.4) * window.dpi,
	pxToMm: (px) => (px * 25.4) / window.dpi,
	inToPx: (inch) => inch * window.dpi,
	pxToIn: (px) => px / window.dpi
});

// remove CK Editor styling
Array.from((document.querySelector("style[data-cke]") as HTMLStyleElement).sheet.cssRules)
	.filter((rule: any) => (rule.selectorText || "").startsWith(".ck-content"))
	.forEach((rule: any) => {
		rule.selectorText = "devnull";
	});

/**
 * typedefs, unfortunately have to be done manually
 */

// @ts-ignore
import type ClassicEditorType from "@types/ckeditor__ckeditor5-build-classic";
import type { default as AppType, T_Permissions } from "/src/App.svelte";

type SVELTE = typeof typeOfSvelte & {
	store: typeof typeOfSvelteStore & {
		writable_ls_sync(
			id: string,
			initValue?: any,
			startStop?: () => void
		): { subscribe: () => any; update: () => void; set: (newVal: any) => void };
	};
	internal: typeof typeOfSvelteInternal;
	once(expected: () => orArr, callback: () => void): () => void;
	setContexts<T>(ctxs: { [key: string | number | symbol]: T }): void;
	getContextsObj<T = any, O = { [key: string]: T }>(): O;
	ensureContext<T = any>(key: string, value: () => T): T;
};

type LIB = typeof lib & {
	ClassicEditor: ClassicEditorType;
	schema: Tschema;
};
declare global {
	namespace globalThis {
		const lib: LIB;
		const svelte: SVELTE;
		const pageComponents: stringOf<PB.PageView>;
		function mmToPx(mm: number): number;
		function pxToMm(px: number): number;
		function inToPx(inch: number): number;
		function pxToIn(px: number): number;
		const instances: stringOf<import("pb/Components/Viewer.svelte").default>;
		const _: typeof _;
		const EVENTS: EventEmitter;
		const permissions: Readable<T_Permissions>;
		const perms: T_Permissions;
		const APP: "SVELTINATOR";
		const dpi: number;
		const app: AppType;
		function initApp(): void;
		function setDebug(v: boolean): void;
		const AppNotifications: any;
	}
	interface Window {
		lib: LIB;
		jQuery: typeof jq;
		$: typeof jq;
		svelte: SVELTE;
		pageComponents: stringOf<PB.PageView>;
		mmToPx(mm: number): number;
		pxToMm(px: number): number;
		inToPx(inch: number): number;
		pxToIn(px: number): number;
		instances: stringOf<import("pb/Components/Viewer.svelte").default>;
		_: typeof _;
		EVENTS: EventEmitter;
		permissions: Readable<T_Permissions>;
		perms: T_Permissions;
		APP: "SVELTINATOR";
		dpi: number;
		app: App;
		initApp: typeof initApp;
		setDebug(v: boolean): void;
		AppNotifications: any;
	}
}

/** MAIN - PageBuilder stuff */

const pageComponents = {};
const instances = {};
const tabulator_instances = {};

makeGlobals({
	pageComponents,
	instances,
	tabulator_instances,
	process: {
		env: process.env
	}
});

// let's do it "dynamically" because dependancies in exports require lib
import("./App.svelte").then(({ default: App }) => {
	function initApp() {
		const old_scroll = window.scrollY;
		window.EVENTS.emit("before_initApp");
		typeof window.app?.$destroy === "function" && window.app.$destroy();
		try {
			window.app = new App({
				target: document.body
			});
		} catch (error) {
			console.error(error);
			alert("critical app error");
		}
		window.EVENTS.emit("after_initApp");
		setTimeout(() => {
			window.scroll(0, old_scroll);
		}, 1000);
	}

	makeGlobals({
		App,
		initApp
	});

	initApp();
});
