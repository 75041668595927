import type ClassicEditorType from "@types/ckeditor__ckeditor5-build-classic";

window.ClassicEditor.defaultConfig = {
	toolbar: {
		shouldNotGroupWhenFull: true,
		items: [
			// "addAttachment",
			"heading",
			"bold",
			"italic",
			"link",
			"bulletedList",
			"numberedList",
			"outdent",
			"indent",
			"imageUpload",
			"blockQuote",
			"insertTable",
			// "mediaEmbed",
			"undo",
			"redo",
			"alignment",
			"code",
			"codeBlock",
			"findAndReplace",
			"fontBackgroundColor",
			"fontColor",
			"fontFamily",
			"fontSize",
			"highlight",
			"horizontalLine",
			"htmlEmbed",
			"imageInsert",
			"sourceEditing",
			"underline",
			"todoList",
			"superscript",
			"subscript",
			"strikethrough",
			"restrictedEditingException",
			"specialCharacters",
			"removeFormat",
			"pageBreak"
		]
	},
	language: "en",
	image: {
		toolbar: ["imageTextAlternative", "imageStyle:inline", "imageStyle:block", "imageStyle:side", "linkImage"]
	},
	table: {
		contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableCellProperties", "tableProperties"]
	},
	heading: {
		options: [
			{ model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
			{
				model: "heading1",
				view: "h1",
				title: "Heading 1",
				class: "ck-heading_heading1"
			},
			{
				model: "heading2",
				view: "h2",
				title: "Heading 2",
				class: "ck-heading_heading2"
			}
		]
	},
	htmlSupport: {
		allow: [
			{
				name: /.*/,
				attributes: true,
				classes: true,
				styles: true
			}
		]
	}
};

export const ClassicEditor = window.ClassicEditor;

/**
 * @module ClassicEditor
 * @memberof lib
 * @see {@link https://github.com/krystian-mw/cke}
 */
export { ClassicEditorType };
