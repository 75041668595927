<script lang="ts">import BsButton from "./BsUI/BsButton.svelte";
export let vertical = false;
export let buttons;
export let min_to_dropdown = false;
export let noBgAndBorder = false;
let more_open = false;
let more_toggle;
const more_id = lib.tacticalUid();
const actionTrackPositionFixed = (node, { el }) => {
    node.style.position = "fixed";
    // Helper function to update the node's position
    const updatePosition = () => {
        if (!el)
            return;
        const rect = el.getBoundingClientRect();
        node.style.top = `${rect.top}px`;
        node.style.left = `${rect.left}px`;
    };
    // Variable to hold the requestAnimationFrame ID
    let animationFrameId = null;
    // Use requestAnimationFrame to track the position of the element in case of scroll or other changes
    const trackPosition = () => {
        if (!el)
            return;
        animationFrameId = window.requestAnimationFrame(() => {
            updatePosition();
            // Continuously call trackPosition to keep tracking the position
            trackPosition();
        });
    };
    trackPosition();
    // Clean up when the action is destroyed
    return {
        update(args) {
            el = args.el;
            trackPosition();
        },
        destroy() {
            // Stop the requestAnimationFrame loop
            if (animationFrameId !== null) {
                cancelAnimationFrame(animationFrameId);
            }
        }
    };
};
</script>

{#if typeof min_to_dropdown === "number" && buttons.length > min_to_dropdown}
	<div class="dropdown hide_down_arrow">
		<button
			bind:this={more_toggle}
			popovertarget={more_id}
			style="
				cursor: pointer;
				padding: 0px;
				margin: 0px;
				background: unset;
				border: none;
				color: white;
				display: flex;
				justify-content: center;
				align-items: center;
			"
			on:click|stopPropagation
		>
			<i class="material-symbols-outlined"> more_vert </i>
		</button>
		<!-- {#if more_open} -->
		<div
			id={more_id}
			popover="auto"
			style="
				margin: 0;
				border: none;
				margin-top: 24px; /* size of icon */
				background: white;
				flex-direction: column;
				padding: 1px;
			"
			on:toggle={(e) => {
				more_open = e.newState === "open";
				e.target.style.display = more_open ? "flex" : "none";
			}}
			use:actionTrackPositionFixed={{ el: more_open && more_toggle }}
		>
			{#each (buttons || []).filter(Boolean) as button}
				<div>
					<BsButton ignore_theme {...button} />
				</div>
			{/each}
		</div>
		<!-- {/if} -->
	</div>
{:else}
	<div class="d-flex" class:flex-column={vertical} class:child-button-no-backgroud-and-border={noBgAndBorder}>
		{#each (buttons || []).filter(Boolean) as button}
			<BsButton {...button} />
		{/each}
	</div>
{/if}

<style lang="scss">.dropdown .dropdown-item,
.dropdown .dropdown-menu {
  min-width: unset;
}</style>
