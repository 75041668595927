import _ from "lodash";

// baseValues = require('./_baseValues'),
// customDefaultsAssignIn = require('./_customDefaultsAssignIn')

import baseValues from "lodash/_baseValues";
import customDefaultsAssignIn from "lodash/_customDefaultsAssignIn";

function selfop<T>(arg: T): T {
	return arg;
}

function fn<T, A>(fn: (...args: A[]) => T | void = _.noop, ...args: A[]): T | void {
	return fn(...args);
}

async function afn<T, A>(fn?: (...args: A[]) => T, ...args: A[]): Promise<T | void> {
	return await _.fn(fn, ...args);
}

function jsonify<T extends JsonComp>(obj: T): T {
	return JSON.parse(JSON.stringify(obj));
}

function regexMatch(str: string, pattern: string | RegExp, flags?: string): string | null {
	return new RegExp(pattern, flags).exec(str)?.[0] || null;
}

function regexExtract(str: string, pattern: string | RegExp, flags?: string): string | null {
	return new RegExp(pattern, flags).exec(str)?.[1] || null;
}

const cell_processors = [
	// if wrapped in quotes, remove them
	(cell: string) => (cell[0] === '"' && cell[cell.length - 1] ? cell.slice(1, -1) : cell),
	// if it's a number, parse it
	// (cell: string) => (isNaN(Number(cell)) ? cell : Number(cell)),
	// if it's a boolean, parse it
	(cell: string) => (cell === "true" ? true : cell === "false" ? false : cell)
	// if it's an object, parse it
	// (cell: string) => {
	// 	try {
	// 		return JSON.parse(cell);
	// 	} catch (e) {
	// 		return cell;
	// 	}
	// }
];

const process_each = (inp, processors) => processors.reduce((acc, processor) => processor(acc), inp);

function parseCsv(raw: string, headers?: string[], seperator = ",", splitter = "\n"): any[] {
	const data = raw.trimEnd().split(splitter);
	const data_headers =
		headers ||
		data
			.shift()
			.split(seperator)
			.map((cell) => process_each(cell, cell_processors));
	const new_data = data.map((row) => _.fromPairs(_.zip(data_headers, row.split(seperator))));
	for (const row of new_data) {
		for (const key of data_headers) {
			try {
				row[key] = process_each(row[key], cell_processors);
			} catch {}
		}
	}

	return new_data;
}

function median(numbers: number[]): number {
	const sorted = Array.from(numbers).sort((a, b) => a - b);
	const middle = Math.floor(sorted.length / 2);

	if (sorted.length % 2 === 0) {
		return (sorted[middle - 1] + sorted[middle]) / 2;
	}

	return sorted[middle];
}

function queueable(cb, on_empty_queue?: () => void) {
	let queue = [];
	let processing = false;

	async function call(...args) {
		queue.push(async () => cb(...args));
		await run();
	}

	async function run() {
		if (queue.length > 0) {
			if (!processing) {
				processing = true;

				const process = queue.shift();

				if (typeof process === "function") {
					await process();
				}

				processing = false;
				await run();
			}
		} else {
			if (typeof on_empty_queue === "function") {
				on_empty_queue();
			}
		}
	}

	call.is_processing = () => processing;

	return call;
}

/**
 * Creates a deferred execution wrapper for an asynchronous function. This wrapper ensures that multiple
 * successive calls to the function will use a single ongoing promise instead of initiating new ones for
 * each call. This mechanism is particularly useful for rate-limiting API calls or managing costly asynchronous
 * operations that should not be performed simultaneously based on rapidly changing input.
 * 
 * The wrapper ensures that the promise is reused until it settles (either resolves or rejects). Once settled,
 * any subsequent call will initiate a new promise. This reuse mechanism also ensures that the latest arguments
 * are used when the promise resolves, as the arguments are fetched dynamically at the time of the promise's
 * resolution.
 *
 * @template T The type of arguments passed to the function. This is a generic placeholder that can represent any type.
 * @template R The type of the result returned by the function. This is another generic placeholder that can represent any type.
 * 
 * @param {Function} cb - The callback function that performs the asynchronous operation. This function must accept
 * another function `getArgs` as its argument, which returns the latest arguments when invoked.
 * 
 * @returns {Function} A function that accepts arguments of type `T` and returns a Promise of type `R`. When invoked,
 * this function will handle the deferral and reuse of the promise based on the described mechanism.
 * 
 * @example
 * // Example usage with an API call simulation
 * const delayedUpperCase = defferWithReplacer(async (getArgs) => {
 *   return new Promise(resolve => {
 *     setTimeout(() => resolve(getArgs().toUpperCase()), 1000);
 *   });
 * });
 *
 * delayedUpperCase("hello").then(console.log); // Prints "WORLD" after 1 second
 * delayedUpperCase("world").then(console.log); // Still prints "WORLD", no new promise is made
 * 
 * @example
 * // Demonstrating argument updates with delays
 * const fetchUserData = defferWithReplacer(async (getArgs) => {
 *   const userId = getArgs();
 *   return fetch(`https://api.example.com/users/${userId}`)
 *     .then(response => response.json());
 * });
 *
 * fetchUserData(1).then(console.log); // Fetches and logs user data for user 1
 * setTimeout(() => {
 *   fetchUserData(2).then(console.log); // Fetches and logs user data for user 2, only if fetching 1 was completed
 * }, 500);
 * 
 */
function defferWithReplacer<T = any, R = void>(cb: (getArgs: () => T) => Promise<R>) {
	let current_promise: Promise<R>;
	let args: T;

	return (new_args) => {
		args = new_args;
		return new Promise((t, c) => {
			if (!current_promise) {
				current_promise = cb(() => args);
				current_promise.finally(() => {
					current_promise = undefined;
				});
			}
			current_promise.then(t).catch(c);
		});
	};
}

function rescale(value: number, from_min: number, from_max: number, to_min: number, to_max: number): number {
	return ((value - from_min) * (to_max - to_min)) / (from_max - from_min) + to_min;
}

function ifFunction<T, PA>(fn: T | ((args: PA) => T), args: PA): T {
	if (typeof fn === "function") {
		return fn(args);
	} else {
		return fn;
	}
}

function asyncDebounce(func, wait) {
	const debounced = _.debounce(async (resolve, reject, bindSelf, args) => {
		try {
			const result = await func.bind(bindSelf)(...args);
			resolve(result);
		} catch (error) {
			reject(error);
		}
	}, wait);

	function returnFunc(...args) {
		return new Promise((resolve, reject) => {
			debounced(resolve, reject, this, args);
		});
	}

	return returnFunc;
}

function attemptElse<ReturnOfAttempt = any, ElseType = any>(
	_attemptArgs: [(...args: any[]) => ReturnOfAttempt, ...any[]],
	_else: ElseType
): ReturnOfAttempt | ElseType {
	const out = _.attempt(..._attemptArgs);
	return out instanceof Error ? _else : out;
}

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

const compiler_cache = {};
function getCompiledTemplate(str, opts = {}) {
	const hash = (str + JSON.stringify(opts)).easyHash();
	if (!compiler_cache[hash]) {
		compiler_cache[hash] = _.template(str, opts);
	}
	return compiler_cache[hash];
}

function wipeObject<T extends stringOf>(obj: {} | T): void {
	// @ts-ignore
	_.forOwn(obj, (value, key) => {
		delete obj[key];
	});
}

function templateAsync(template: string, options?: any, guard?: any) {
	var settings = _.templateSettings.imports._.templateSettings || _.templateSettings;

	template = _.toString(template);
	options = _.assignInWith({}, options, settings, customDefaultsAssignIn);

	var imports = _.assignInWith({}, options.imports, settings.imports, customDefaultsAssignIn),
		importsKeys = _.keys(imports),
		importsValues = baseValues(imports, importsKeys);
	const result = _.attempt(function () {
		let nonAsyncOutput = _.template(template, options).source;
		nonAsyncOutput = nonAsyncOutput.replace("function", "async function");
		return new Function(importsKeys, `return ${nonAsyncOutput}`).apply(undefined, importsValues);
	});
	// console.log(result);
	if (_.isError(result)) {
		throw result;
	}
	return result;
}

function executeIfTrue<T>(condition: boolean | (() => boolean), fn: () => T): T {
	if (typeof condition === "function") {
		condition = condition();
	}
	if (condition) {
		return fn();
	}
}

declare module "lodash" {
	interface LoDashStatic {
		selfop: typeof selfop;
		fn: typeof fn;
		afn: typeof afn;
		jsonify: typeof jsonify;
		regexMatch: typeof regexMatch;
		regexExtract: typeof regexExtract;
		parseCsv: typeof parseCsv;
		queueable: typeof queueable;
		median: typeof median;
		rescale: typeof rescale;
		ifFunction: typeof ifFunction;
		asyncDebounce: typeof asyncDebounce;
		getCompiledTemplate: typeof _.template;
		attemptElse: typeof attemptElse;
		wipeObject: typeof wipeObject;
		templateAsync: typeof templateAsync;
		executeIfTrue: typeof executeIfTrue;
		defferWithReplacer: typeof defferWithReplacer;
	}
}

_.mixin({
	selfop,
	fn,
	afn,
	jsonify,
	regexMatch,
	regexExtract,
	parseCsv,
	queueable,
	median,
	rescale,
	ifFunction,
	asyncDebounce,
	getCompiledTemplate,
	attemptElse,
	wipeObject,
	templateAsync,
	executeIfTrue,
	defferWithReplacer
});

export default _;
