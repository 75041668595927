import { SvelteComponent, SvelteComponentTyped } from "svelte";
import { factoryFormConfig } from "./factories";
import { denestTable, get2 } from "./get";
import { mutateVared } from "./mutate";
import _merge from "lodash/merge";
import { makeUpload, styler, xf } from ".";
import { linkUpload } from "./upload";
import { wait } from "./commons";
import RecordSelector from "src/routes/Admin/AdminTables/RecordSelector.svelte";
import Html from "../components/Html.svelte";

export function dialog(config: {
	context?: stringOfAny;
	component: SvelteComponentTyped | SvelteComponent | any;
	includeMinHeight?: boolean;
	waitFor?: string[];
	title?: string;
	props?: {
		buttons?: { label: string; action: (ctx: any) => void }[];
		onMount?: (comp) => void;
		[key: string]: any;
	};
	onDestroy?(): void;
}): Promise<SvelteComponent> {
	return new Promise(function (resolve, reject) {
		import("../components/Dialog.svelte").then(({ default: component }) => {
			const el = document.createElement("div");
			document.body.appendChild(el);

			const comp = new component({
				target: el,
				props: config,
				context:
					config.context instanceof Map
						? config.context
						: Object.keys(config.context || {}).reduce((p, c) => {
								p.set(c, config.context[c]);
								return p;
						  }, new Map())
			});

			const destroyer = function () {
				try {
					comp.$destroy();
					el.remove();
				} catch (error) {
				} finally {
					// console.log("destroyer done");
					(config?.onDestroy || lib.xf.noop)();
					window.EVENTS.off("before_initApp", destroyer);
				}
			};

			window.EVENTS.on("before_initApp", destroyer);

			comp.$set({
				...comp.$$set,
				destroyer
			});

			// comp

			if (!comp.destroyer) {
				comp.destroyer = destroyer;
			}

			// comp.$on("dialogclose", () => {
			// 	comp.$destroy();
			// 	el.remove();
			// });

			resolve(comp);
		});
	});
}

export function confirmDialog(confirm: noop, cancel?: noop, text = "Are you sure?") {
	import("../components/Html.svelte").then(({ default: Html }) => {
		dialog({
			component: Html,
			includeMinHeight: false,
			props: {
				html: text,
				buttons: [
					{
						label: "Cancel",
						action: () => typeof cancel === "function" && cancel()
					},
					{
						label: "Confirm",
						action: confirm
					}
				]
			}
		});
	});
}

export function confirmDialogPromise({
	title = '',
	text = "Are you sure?",
	component = undefined,
	props = {},
	yes = "Confirm",
	no = "Cancel"
} = {}) {
	return new Promise(function (resolve, reject) {
		import("../components/Html.svelte").then(({ default: Html }) => {
			dialog({
				component: component || Html,
				includeMinHeight: false,
				title,
				props: {
					...props,
					html: text,
					buttons: [
						{
							label: no,
							action: reject
						},
						{
							label: yes,
							action: resolve
						}
					]
				}
			});
		});
	});
}

export async function confirmDialogPromiseBoolean(...args: Parameters<typeof confirmDialogPromise>) {
	try {
		await confirmDialogPromise(...args);
		return true;
	} catch {
		return false;
	}
}

export function promptDialog({
	allow_cancel = false,
	title = "",
	options = []
}: {
	allow_cancel?: boolean;
	title?: string;
	options: string[];
}) {
	return new Promise((resolve, reject) => {
		lib
			.dialog({
				component: Html,
				title,
				props: {
					buttons: [
						...(allow_cancel
							? [
									{
										label: "Cancel",
										action() {
											reject();
										}
									}
							  ]
							: []),
						...options.map((opt) => ({
							label: opt,
							action() {
								resolve(opt);
							}
						}))
					]
				}
			})
			.then(({ $$ }) => {
				$$.on_destroy.push(reject);
			});
	});
}

export function addressDialog(
	config: {
		id?: number;
		callback?: (address: Tschema_address) => void;
	} = {}
): Promise<Tschema_address> {
	return new Promise((resolve, reject) => {
		const { callback, id } = config;
		import("../components/AddressForm.svelte").then(({ default: Address }) => {
			dialog({
				component: Address,
				props: {
					id,
					buttons: [
						{
							label: "Cancel",
							action: reject
						},
						{
							label: "Save",
							action: (ctx) => {
								if (typeof callback === "function") {
									callback(ctx);
								}
								resolve(ctx);
							}
						}
					]
				}
			});
		});
	});
}

export async function editorDialog<T extends TschemaTableName>(config: editorDialogOptions<T>): Promise<void> {
	const { default: editors } = await import("src/components/FormUI/editors");
	if (!!config?.editor) {
		for (const editor of Object.keys(editors)) {
			if (!config.editors) config.editors = {};
			config.editors[editor] = !!config.editor.default ? config.editor.default : config.editor;
		}
	}
	const { default: CreateDialog } = await import("src/components/CreateDialog.svelte");

	if (config.id) {
		const {
			// @ts-ignore
			res: [res]
		} = await get2(
			await (config?.preFetch || xf.selfop)({
				tableName: config.tableName,
				alias: "res",
				where: {
					id: {
						eq: config.id
					}
				}
			})
		);

		config.initialData = {
			...res,
			...config.initialData
		};
	}

	config.initialData = await (config.postFetch || xf.selfop)(config.initialData || {});

	const buttonActions = {
		async unlink(ctx) {
			const { id, tableName, unlinkParent, unlinkParentId, masterContext } = config;
			if (!id || !tableName || !unlinkParent || !unlinkParentId || !masterContext) {
				throw new Error(
					[
						`id ${id}`,
						`tableName ${tableName}`,
						`unlinkParent ${unlinkParent}`,
						`unlinkParentId ${unlinkParentId}`,
						`masterContext ${!!masterContext && !!lib.sv(masterContext)}`
					]
						.join(" or ")
						.concat(" not set")
				);
			}
			try {
				const { res } = await lib.get2<{ res: [TschemaData_Table<any>] | null }>({
					alias: "res",
					tableName: unlinkParent,
					where: {
						id: {
							eq: unlinkParentId
						}
					},
					fields: [`Attachment { ${denestTable("Attachment")} }`]
				});

				if (res) {
					const rec = res[0];

					const Attachment = rec.Attachment.filter((_) => _.id !== id);

					await mutateVared(
						{
							tableName: "Job",
							id: unlinkParentId,
							mutation: "update",
							forceAttachments: true
						},
						{
							Attachment
						}
					);

					masterContext.update((state) => {
						Object.assign(state, { Attachment });
						return state;
					});
				}
			} catch (error) {
				console.error(error);
				editorDialog(config);
			}
		},
		cancel: xf.noop,
		save: async (ctx) => {
			try {
				if (!config.preMutate) {
					config.preMutate = (v) => v;
				}

				if (!!config?.editor?.hooks?.preMutate) {
					ctx = config.editor.hooks.preMutate(ctx);
				}
				const id =
					(await (config?.actions?.mutate || mutateVared)(
						{
							tableName: config.tableName,
							mutation: config.id ? "update" : "create",
							id: config.id
						},
						await config.preMutate(lib.xf.Object(ctx).burst("Attachment"))
					)) || config.id;
				if (ctx?.Attachment?.length) {
					for (const attach of ctx.Attachment as TschemaData_Attachment[]) {
						await linkUpload({
							tableName: config.tableName,
							Attachment: attach.id,
							id,
							field: "Attachment"
						});
					}
				}

				if (typeof config.callback === "function") {
					config.callback(id);
				}
			} catch (e) {
				console.error(e);
			}
		}
	};

	let preTableConfig = factoryFormConfig(config.tableName, config.factoryFormConfigArgs);

	/**
	 * tableConfig hooks MUST return the config back
	 */

	if (!Array.isArray(config?.hooks?.tableConfig) && typeof config?.hooks?.tableConfig === "function") {
		config.hooks.tableConfig = [config?.hooks?.tableConfig];
	}

	for (const tableConfigHook of config?.hooks?.tableConfig || []) {
		if (typeof tableConfigHook === "function") {
			const res = await tableConfigHook(preTableConfig);
			if (res) {
				preTableConfig = res;
			} else {
				console.error("A `tableConfig` hook returned a undefined value");
				debugger;
			}
		}
	}

	dialog({
		component: CreateDialog,
		context: config.context || {},
		title: `${config.id ? "Update" : "Create"} ${config.tableName.pretty}`,
		props: {
			tableName: config.tableName,
			initialData: config.initialData,
			editors: config.editors,
			preloadOptions: config.preloadOptions,
			hooks: config?.editor?.hooks,
			buttonActions,
			tableConfig: preTableConfig,
			options: config.options,
			buttons: [
				...[
					{
						label: "Unlink",
						action: buttonActions.unlink
					}
				].if(config.tableName === "Attachment" && config.unlinkParent && config.unlinkParentId),
				{
					label: "Cancel",
					action: buttonActions.cancel
				},
				{
					label: config.id ? "Update" : "Create",
					action: buttonActions.save
				}
			]
		}
	});
}

export const creatorDialog = editorDialog;

export function dialogOptions<T = string>(config: {
	options: T[];
	onSelect: (option: T, cb: () => void) => void;
	// onCancel: () => void;
}) {
	import("src/components/DialogOptions.svelte").then(({ default: component }) => {
		dialog({
			component,
			props: {
				...config
			}
		});
	});
}

export function selectRecordDialog<T extends TschemaTableName>(config: {
	tableName: T;
	callback(id: number, data?: TschemaData_Table<T>): void;
	mod?(config: View<"Table">, cb: (id: string, data?: TschemaData_Table<T>) => void): View<"Table">;
	data?: TschemaData_Table<T>[];
	addOnly?: boolean;
	title?: string;
	newMode?: boolean;
	newModeProps?: any;
	newModeButtons?: any[];
}): void {
	let comp: SvelteComponent;

	async function cb(id) {
		let _moreData;
		try {
			const {
				moreData: [moreData]
			} = await lib.get2({
				tableName: config.tableName,
				where: {
					id: {
						eq: String(id)
					}
				},
				alias: "moreData",
				fields: [lib.denestTable(config.tableName, 2)]
			});

			if (moreData) {
				_moreData = moreData;
			}
		} catch (error) {}
		comp && comp.destroyer();
		if (typeof config.callback === "function") {
			config.callback(id, _moreData);
		} else {
			console.error("NO CALLBACK SET");
		}
	}

	if (config.newMode) {
		lib.dialog({
			component: RecordSelector,
			props: {
				tableName: config.tableName,
				buttons: [
					{
						label: "Cancel",
						action() {
							// cancel();
						}
					},
					...(config.newModeButtons || [])
				],
				cb(id) {
					cb(id);
				},
				...(config.newModeProps || {})
			}
		});
	} else
		import("src/components/Views/View.svelte").then(async ({ default: component }) => {
			const view: View<"Table"> = {
				view: "Table",
				config: {
					tableName: config.tableName,
					mode: "ajax",
					...(!!config.data
						? {
								mode: "classic",
								async fetcher() {
									return config.data;
								}
						  }
						: {}),
					async tabulator(tabulatorConfig) {
						tabulatorConfig.rowDblClick = function (e, row) {
							cb(row.getData().id, row.getData());
						};
						tabulatorConfig.paginationModed = "remote";
						return tabulatorConfig;
					},
					editor: {
						callback(id, mutation) {
							if (mutation === "create") {
								cb(id);
							}
						}
					}
				}
			};

			comp = await dialog({
				component,
				title: config.title || `Select a ${config.tableName}`,
				props: {
					addOnly: config.addOnly,
					view: typeof config.mod === "function" ? config.mod(view, cb) : view
				}
			});
		});
}

window.editor = editorDialog;

interface attachmentDialogArguments {
	tableName: TschemaTableName;
	id: string | number;
}

export async function attachmentDialog(_config: attachmentDialogArguments | string) {
	const config: attachmentDialogArguments = typeof _config === "string" ? JSON.parse(_config) : _config;

	dialog({
		component: (await import("src/components/AttachSimple.svelte")).default,
		props: {
			...config
		}
	});
}

export function addAttachmentInForm({ tableName, id, instance_id = undefined, field }): Promise<number[]> {
	return new Promise(async function (resolve) {
		editorDialog({
			tableName: "Attachment",
			hooks: {
				tableConfig(config: FormConfig) {
					// change order
					const newGroup: FormConfig = { type: "Group", items: [] };
					const tempItems: FormConfig[] = [];
					const order: (keyof Tschema_Attachment)[] = ["notes", "AttachmentType"];
					for (const group of config.items) {
						for (const item of group.items) {
							tempItems.push(item);
						}
					}
					for (const orderId of order) {
						newGroup.items.push(tempItems.find(({ id }) => id == orderId));
					}
					newGroup.items.push({
						type: "File",
						label: "File",
						id: "file"
					});
					config.items = [newGroup];
					return config;
				}
			},
			actions: {
				async mutate(
					{},
					data: {
						notes: string;
						AttachmentType: { value: string };
						file: FileList;
					}
				) {
					const ids = [];
					for (let index = 0; index < data.file.length; index++) {
						const file = data.file[index];
						const form = new FormData();
						form.set("files", file);
						await makeUpload({
							form,
							tableName,
							id,
							field,
							async callback(id) {
								ids.push(
									await mutateVared(
										{
											tableName: "Attachment",
											id
										},
										// @ts-ignore
										data
									)
								);
							}
						});
					}
					await wait(1e3);
					PubSub.publish(instance_id ? `UPDATE_TABULATOR/${instance_id}` : "UPDATE_TABULATOR");
					return resolve(ids);
				}
			}
		});
	});
}
