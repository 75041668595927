export async function notify(config: { text: string; timeout?: number } | string) {
	if (typeof config === "string") {
		config = { text: config };
	}
	const { default: Notifications } = await import("src/components/Notifications");
	// Notifications.add({
	// 	message: config.text,
	// 	title: "(old styled lib.notify)",
	// 	type: "info"
	// });
	// @ts-ignore
	// import("src/components/Notify.svelte").then(({ default: comp }) => {
	// 	const el = document.createElement("div");
	// 	document.body.appendChild(el);

	// 	const notifier = new comp({
	// 		target: el,
	// 		// @ts-ignore
	// 		props: config
	// 	});

	// 	setTimeout(
	// 		function () {
	// 			notifier.$destroy();
	// 		},
	// 		// extra 150ms to allow the animation to play
	// 		typeof config !== "string" ? +config?.timeout + 150 || 5150 : 5150
	// 	);
	// });
}

//window.notify = notify;
