<script lang="ts">import { AUTH_EVENTS } from "src/lib";
import BsButton from "./BsUI/BsButton.svelte";
import BsCard from "./BsUI/BsCard.svelte";
import BsInput from "./BsUI/BsInput.svelte";
let username;
let password;
let error;
svelte.onMount(() => {
    AUTH_EVENTS.on("login_error", (err) => {
        // username = "";
        // password = "";
        if (err.message === "Request failed with status code 401") {
            error = "Invalid username or password";
        }
        else {
            error = err;
        }
    });
});
</script>

<form
	style="max-width: 500px"
	on:submit={(e) => {
		e.preventDefault();
		lib.AUTHORIZE.withPassword({ username, password });
	}}
>
	<BsCard header="Login">
		{#if error}
			<div class="alert alert-danger">
				{typeof error === "string" ? error : error.message}
			</div>
		{/if}
		<BsInput label="Username" bind:value={username} name="username" />
		<BsInput label="Password" bind:value={password} name="password" type="password" />
		<BsButton text="Submit" type="submit" />
	</BsCard>
</form>
