<script lang="ts" context="module">let missing_count = 0;
window.logmc = () => console.log("Missing CommonButton:", missing_count++);
const icon_defaults = {
    remove: "Remove",
    add: "Add",
    edit: "Edit",
    cancel: "Cancel"
};
</script>

<script lang="ts">export let action = _.noop;
export let icon_action = _.noop;
export let text = "";
export let icon = "";
export let iconRight = false;
export let disabled = false;
export let type = "button";
export let color = "secondary";
export let icon_abbr = icon_defaults[icon] || "";
export let className = "";
export let html_tooltip = undefined;
export let ignore_theme = false;
export let not_tabbable = false;
export let popovertarget = undefined;
let button;
function hasElement(parent, element) {
    if (parent === element) {
        return true;
    }
    for (let child of parent.children) {
        if (hasElement(child, element)) {
            return true;
        }
    }
    return false;
}
svelte.onMount(() => {
    if (icon && !text && !icon_abbr && !hasElement(document.querySelector("nav"), button)) {
        console.log(button);
        console.error(`NO HELPER TEXT`);
        window.logmc();
    }
    if ($$props.abbr) {
        console.error("misuse of prop name");
    }
});
</script>

<button
	bind:this={button}
	{type}
	tabindex={not_tabbable ? -1 : undefined}
	class="border rounded btn btn-{color} d-flex align-items-center p-1 {'disabled'.if(disabled)} {className}"
	class:ignore_theme
	on:click={action}
	{...disabled ? { disabled: true } : {}}
	{popovertarget}
>
	{#if !iconRight}
		{#if icon}
			<!-- svelte-ignore a11y-click-events-have-key-events -->
			<i class={icon.startsWith("symbol#") ? "material-symbols-outlined" : "material-icons"} on:click={icon_action}>
				{#if icon_abbr}
					<abbr title={icon_abbr}> {icon.replace("symbol#", "")}</abbr>
				{:else}
					{icon.replace("symbol#", "")}
				{/if}
			</i>
		{/if}
	{/if}
	{#if html_tooltip}
		<span class="tooltiptext">{@html html_tooltip}</span>
	{/if}
	{#if typeof text !== "undefined"}
		<span>{text}</span>
	{/if}
	{#if iconRight}
		{#if icon}
			<!-- svelte-ignore a11y-click-events-have-key-events -->
			<i class={icon.startsWith("symbol#") ? "material-symbols-outlined" : "material-icons"} on:click={icon_action}>
				{#if icon_abbr}
					<abbr title={icon_abbr}> {icon.replace("symbol#", "")}</abbr>
				{:else}
					{icon.replace("symbol#", "")}
				{/if}
			</i>
		{/if}
	{/if}
</button>

<style lang="scss">abbr {
  text-decoration: none;
}

button {
  position: relative;
  display: inline-block;
}

button .tooltiptext {
  visibility: hidden;
  background: white;
  border: 1px solid black;
  text-align: start;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

button:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.btn:hover {
  color: unset !important;
}</style>
