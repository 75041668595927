<script lang="ts">import { setupTabulator } from "./lib";
export let tableName;
export let cb;
export let destroyer;
export let where = undefined;
export let link;
let el;
const ctx = svelte.getContext("context");
svelte.onMount(async () => {
    const instance = await setupTabulator({
        el,
        editable: false,
        tableName,
        mod(conf) {
            function pc(c) {
                for (const col of c) {
                    if (Array.isArray(col.columns)) {
                        pc(col.columns);
                    }
                    else {
                        link.processColumn(col);
                    }
                }
            }
            typeof (link === null || link === void 0 ? void 0 : link.processColumns) === "function" && pc(conf.columns);
            !link && conf.columns.shift();
            return conf;
        },
        where
    });
    $ctx = instance;
    if (!link) {
        instance.on("rowDblClick", function (e, row) {
            destroyer();
            cb(row.getData());
        });
    }
    else {
        let ready = false;
        instance.on("dataLoading", function () {
            ready = false;
        });
        instance.on("dataProcessed", function () {
            const rows = link.selected.map((id) => instance.getRow(id)).filter(Boolean);
            rows.forEach((row) => row.select());
            ready = true;
        });
        instance.on("rowSelected", function (row) {
            if (ready) {
                link.selected.push(row.getIndex());
                if (!link.disable_autolink) {
                    // @ts-ignore
                    lib.linker(Object.assign(Object.assign({}, link), { foreign_id: row.getIndex() }));
                }
            }
        });
        instance.on("rowDeselected", function (row) {
            if (ready) {
                link.selected.remove(row.getIndex());
                // @ts-ignore
                if (!link.disable_autolink) {
                    lib.linker(Object.assign(Object.assign({}, link), { foreign_id: row.getIndex(), remove: true }));
                }
            }
        });
    }
});
</script>

{#if link}
	<details>
		<summary>Use checkboxes to link and unlink</summary>
	</details>
{:else}
	<details>
		<summary>Double click to select foreign record</summary>
	</details>
{/if}
<div bind:this={el} />
