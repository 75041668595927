import { Dayjs } from "dayjs";
import { tick } from "svelte";
import { get_store_value } from "svelte/internal";
import { writable } from "svelte/store";

export const dailyView = writable<boolean>(window.innerWidth <= 824);

window.addEventListener("resize", (e) => {
	dailyView.set(window.innerWidth <= 824);
});

export const preferences = writable<{
	theme: "light" | "dark";
	units: {
		temperature: "C" | "F" | "K";
		pressure: "PSI" | "BAR" | "hPa";
		speed: "MPH" | "KMPH" | "MPS";
		date: "YYYY-MM-DD" | string;
		time: "HH:mm" | string;
		datetime: "YYYY-MM-DD HH:mm" | string;
	};
}>({
	theme: "light",
	units: {
		temperature: "F",
		pressure: "BAR",
		speed: "MPH",
		date: "YYYY-MM-DD",
		time: "HH:mm",
		datetime: "YYYY-MM-DD HH:mm"
	}
});

export function formatDayjs(dayjs: Dayjs, unit: "date" | "time" | "datetime"): string {
	return dayjs.format(get_store_value(preferences).units[unit]);
}

export const uploads = writable<
	stringOf<{
		file: File;
		progress: number;
		meta: {
			[key in "meta_1" | "meta_2" | "meta_3" | "meta_4" | "meta_5"]?: string;
		};
	}>
>({});

export const DEBUG_APP = writable(false);

window.setDebug = (bool) => {
	DEBUG_APP.set(bool);
};

export const AppNotifications = writable<AppNotifications_Store[]>([]);

export async function removeNotification(id: string): Promise<boolean> {
	const not = get_store_value(AppNotifications).find(({ id: not_id }) => not_id == id);
	if (!not) return false;
	if (not.remove) {
		await not.remove();
	} else {
		AppNotifications.update((state) => {
			state = state.filter(({ id: not_id }) => not_id != id);
			return state;
		});
	}
	await tick();
	return true;
}

window.AppNotifications = AppNotifications;

export const INTER_STORE = (function () {
	const store = new Map();

	return {
		get(id: string) {
			return store.get(id);
		},
		set(data: any) {
			const uid = lib.tacticalUid();
			store.set(uid, data);
			return uid;
		}
	};
})();

export const USER = writable<TschemaData_account>(null);
