import axios from "axios";
import { pascalCase } from "change-case";
import { xf } from "./";

import formatGql from "graphql-prettier";
import { DEBUG_APP, USER } from "src/store";

export async function query<T = unknown>(
	query?: string,
	config?: {
		variables?: Object;
		outputToArrayLength?: number;
		safeCallback?(data: { errors: any; data: T }): void;
		onSuccessButErrors?(errors: any[]): void;
		no_tolerate_errors?: boolean;
	},
	callback?: (data: any) => void,
	devFilter?: string
): Promise<T> {
	// @ts-ignore
	const { setLoading } = await import("src/components/Nav.svelte");
	setLoading(true);
	if (lib.getStoreValue(DEBUG_APP)) {
		console.log(query);
	}
	try {
		let {
			data: { data, errors }
		} = await axios.post(`${lib.getEnv("GRAPHQL_ENDPOINT")}${`?${devFilter}`.if(!!devFilter)}`, {
			query,
			...config,
			...xf.iifeCustom(() => {
				if (!config) return {};
				if (config.variables) {
					return {
						variables: JSON.stringify(config.variables)
					};
				}
				return {};
			})
		});

		if (typeof config?.safeCallback === "function") {
			setLoading(false);
			config?.safeCallback({ data, errors });
			return;
		}

		if ((!data && !!errors) || (!!errors && !!config?.no_tolerate_errors)) {
			throw errors;
		} else {
			if (config?.outputToArrayLength !== undefined) {
				const temp = [];
				for (let index = 0; index < config.outputToArrayLength; index++) {
					temp.push(data[`_${index}`][0]);
				}
				data = temp;
			}
			if (typeof callback === "function") callback(data);
			if (!!errors) {
				typeof config?.onSuccessButErrors === "function" && config.onSuccessButErrors(errors);
				const user = lib.getStoreValue(USER);
				if (
					lib.getStoreValue(DEBUG_APP) ||
					Array.ensure(user?.roles).find(({ name }) => name === "admin" || name === "debug")
				) {
					lib.notify("QUERY RETURNED WITH ERRORS");
					for (const error of errors) {
						console.error(error);
					}
				}
			}
			return data;
		}
	} catch (error) {
		throw error;
	} finally {
		setLoading(false);
	}
}

export default query;

const gqlDataTypeMap = {
	string: "String",
	integer: "Int",
	datetime: "DateTime",
	date: "JustDate",
	boolean: "Boolean",
	decimal: "Float",
	float: "Float",
	// enum: `${pascalCase(tN)}${pascalCase(field as string)}Enum`,
	"text ARRAY": "[String]",
	"string ARRAY": "[String]",
	time: "String",
	integerArray: "[Int]"
};

function contstructWhereFilter(
	whereFilter?: GqlQueryPart,
	variables?: stringOfstring,
	closeBracket: boolean = true
): string {
	if (!whereFilter) return "";
	if (whereFilter.type === "root") {
		return `(where: { ${whereFilter.children.map((child) => contstructWhereFilter(child, variables)).join("\n")} }${
			closeBracket ? ")" : ""
		}`;
	} else if (whereFilter.type === "table") {
		return `${whereFilter.tableName}: { ${whereFilter.children
			.map((child) => contstructWhereFilter(child, variables))
			.join("\n")} }`;
	} else if (whereFilter.type === "reverse") {
		return `${pascalCase(whereFilter.tableName)}: { ${whereFilter.children
			.map((child) => contstructWhereFilter(child, variables))
			.join("\n")} }`;
	} else if (whereFilter.type === "field") {
		return `${whereFilter.field}: { ${Object.entries(whereFilter.where || {})
			.map(([key, value]) => {
				const isVar = typeof value === "string" && value.match(/^\$([A-z]|_)*$/);

				console.log("isVar", value, isVar);

				if (isVar) {
					console.log(whereFilter);

					variables[value as string] = whereFilter.field === "id" ? "String" : gqlDataTypeMap[whereFilter.fieldDef.type];
				}

				return isVar ? `${key}: ${value}` : `${key}: ${JSON.stringify(value)}`;
			})
			.join(",")} }`;
	} else {
		console.warn("undefined gql part", whereFilter);
		return "";
	}
}

function constructData(data: stringOfAny, variables, tableName: TschemaTableName, startWithBracket: boolean = true) {
	if (!data) return "";
	let out = ``;

	for (const [key, value] of Object.entries(data)) {
		if (value) {
			// @ts-ignore
			out += `${key}: ${xf.dynamicSwitch(schema[tableName][key].type, {
				enum: value,
				foreign: value?.value || JSON.stringify(value),
				link: value?.value || JSON.stringify(value),
				default: JSON.stringify(value)
			})},`;
		} else {
			out += `${key}: $${tableName}_${key},`;
			variables[`$${tableName}_${key}`] = `${
				!!schema[tableName][key].foreign || !!schema[tableName][key].link
					? "ID"
					: gqlDataTypeMap[schema[tableName][key].type]
			}${schema[tableName][key].required ? "!" : ""}`;
		}
	}

	return `${startWithBracket ? "(" : ","}data: { ${out} })`;
}

function preConstructQuery(
	obj: GqlQueryPart,
	variables?: stringOfstring,
	queryType?: "query" | "create" | "update" | "delete"
) {
	if (obj.type === "root") {
		const variables = {};
		const rawQuery = obj.children
			.map((child) => preConstructQuery(child, variables, queryType || obj.queryType))
			.join("\n");

		const out = `${
			obj.queryType
				? xf.dynamicSwitch(obj.queryType, {
						default: "mutation",
						query: "query"
				  })
				: "query"
		} ${
			Object.keys(variables).length > 0
				? `(${Object.entries(variables)
						.map(([key, value]) => `${key}: ${value}`)
						.join(",")})`
				: ""
		} { ${rawQuery} }`;

		return {
			query: out,
			variables
		};
	} else if (obj.type === "field") {
		return obj.field;
	} else if (obj.type === "table") {
		return `${queryType === "query" ? "" : queryType || ""}${obj.tableName}${contstructWhereFilter(
			obj.whereClause,
			variables,
			!obj.data
		)}${constructData(obj.data, variables, obj.tableName, !obj.whereClause)}${
			queryType !== "delete" ? ` { ${obj.children.map((child) => preConstructQuery(child, variables)).join("\n")} } ` : ""
		}`;
	} else if (obj.type === "reverse") {
		return `${obj.tableName}${contstructWhereFilter(obj.whereClause, variables)} { ${obj.children
			.map((child) => preConstructQuery(child, variables))
			.join("\n")} }`;
	} else {
		console.warn("undefined gql part", obj);
		return "";
	}
}

export function contstructQuery(
	obj: GqlQueryPart,
	format: boolean = true
): {
	query: string;
	variables: stringOfstring;
} {
	console.groupCollapsed("Debugging gql query builder");
	const { query, variables } = preConstructQuery(obj) as {
		query: string;
		variables: stringOfstring;
	};
	try {
		return { query: format ? formatGql(query) : query, variables };
	} catch (e) {
		return { query: "Invalid Query\n" + query, variables: {} };
	} finally {
		console.groupEnd();
	}
}
