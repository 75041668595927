export function styler(name?: string) {
	return DynamicStyler.create(name);
}

export class DynamicStyler {
	private LABEL: string;
	private ELEMENT: HTMLStyleElement;

	constructor(public name: string = lib.tacticalUid()) {
		this.LABEL = `__styler_${name}`;

		if (!window[this.LABEL]) {
			window[this.LABEL] = document.createElement("style");
			window[this.LABEL].id = this.LABEL;
			document.body.appendChild(window[this.LABEL]);
		}

		this.ELEMENT = window[this.LABEL];

		DynamicStyler.clearEmpty();
	}

	importantise() {
		this.ELEMENT.remove();
		document.body.appendChild(this.ELEMENT);

		DynamicStyler.clearEmpty();
	}

	get() {
		DynamicStyler.clearEmpty();
		return this.ELEMENT.innerText;
	}

	set(textOrCallback: string | ((current: string) => string)) {
		const newText = typeof textOrCallback === "string" ? textOrCallback : textOrCallback(this.ELEMENT.innerText);

		if (this.ELEMENT.innerText != newText) this.ELEMENT.innerText = newText;

		DynamicStyler.clearEmpty();
	}

	destroy() {
		this.set("");
		DynamicStyler.clearEmpty();
	}

	static clearEmpty() {
		Array.from(document.querySelectorAll("style"))
			.filter((el) => !!el.id.match(/__styler_[0-9]/))
			.filter((el) => !el.innerText);
		// .forEach((el) => el.remove());
	}

	static create(name?: string) {
		return new DynamicStyler(name);
	}
}
