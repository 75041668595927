import _dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";
import isoWeek from "dayjs/plugin/isoWeek";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import lt from "dayjs/plugin/localizedFormat";
import wt from "dayjs/plugin/weekYear";
import customParseFormat from "dayjs/plugin/customParseFormat";

_dayjs.extend(isToday);
_dayjs.extend(utc);
_dayjs.extend(isBetween);
_dayjs.extend(isoWeek);
_dayjs.extend(timezone);
_dayjs.extend(advancedFormat);
_dayjs.extend(weekOfYear);
_dayjs.extend(lt);
_dayjs.extend(wt);
_dayjs.extend(isSameOrBefore);
_dayjs.extend(isSameOrAfter);
_dayjs.extend(customParseFormat);

const dayjsUTC = _dayjs.utc;

export { _dayjs as dayjs, dayjsUTC };

export function formatDate(args: {
	value?: string | Date;
	date?: boolean;
	time?: boolean;
	template?: string;
	utc?: boolean;
	timezone?: string;
}) {
	const AC = lib.getStoreValue(lib.APP_CONFIG);

	const value = args.value || new Date();
	const date = lib.matchType(args.date, "boolean", false);
	const time = lib.matchType(args.time, "boolean", false);
	const template =
		args.template ||
		((date && time) || (!date && !time)
			? AC.datetime_defaults.datetime
			: time
			? AC.datetime_defaults.time
			: AC.datetime_defaults.date);

	return (args.utc ? dayjsUTC : _dayjs)(value)
		.tz(args.timezone || undefined)
		.format(template);
}
