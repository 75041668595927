import { query } from "./query";

import { camelCase, pascalCase, snakeCase } from "change-case";

import isEqual from "lodash/isEqual";

export declare interface getInput {
	tableName?: TschemaTableName;
	rawQuery?: string;
	returnRaw?: boolean;
	fields?: (string | string[])[];
	where?:
		| any
		| {
				[key: string]: { [key: string]: string | number } | string | number;
		  };
	pagination?: {
		limit?: number;
		offset?: number;
		order_by?: string;
		order?: "ASC" | "DESC";
	};
	alias?: string;
	variables?: any;
	_printed_var_defs?: string;
}

export function denestTable<T extends TschemaTableName>(
	tN: T,
	maxLevels: number = 0,
	level: number = 0,
	wrappedName?: string
): string {
	const schema = lib.schema;
	if (!(tN in schema)) {
		console.error(tN, "is not in the schema");
		return ``;
	}
	let out = ``;
	for (const field in schema[tN]) {
		if (schema[tN][field].reverse) continue;
		if (!["foreign", "link", "externalLink"].includes(schema[tN][field].type)) {
			out += `${field}\n`;
		} else {
			if (level < maxLevels) {
				const newTable = !!schema[tN][field].foreign
					? schema[tN][field].foreign.table
					: !!schema[tN][field].link
					? schema[tN][field].link.table
					: !!schema[tN][field].externalLink
					? schema[tN][field].externalLink.name
					: false;

				if (!newTable) {
					console.error(tN, field, "tried to denest", newTable, "which does not exist in the schema");
					continue;
				}
				out += denestTable(pascalCase(newTable) as TschemaTableName, maxLevels, level + 1, field);
			} else {
				const real_table_name = lib.schema[tN][field][lib.schema[tN][field].type].table;
				out += `${field} { id ${`location preview mimetype original_name `.if(
					real_table_name === "attachamizer_attachment"
				)}}`;
			}
		}
	}
	return wrappedName || level !== 0
		? `
		${wrappedName || pascalCase(tN)} {
			${out}
		}`
		: out;
}

function _get2<T = any>(
	_config: getInput | getInput[],
	callback?: (data: any) => void,
	devFilter?: string,
	countOnly: boolean = false
): Promise<T> {
	if (!_config) throw "src/lib/get.ts 220:19 - No config provided";

	if (!Array.isArray(_config) && _config.rawQuery) return query<T>(_config.rawQuery, { variables: _config.variables });

	if (!Array.isArray(_config)) _config = [_config];

	const outputToArray: boolean = (() => {
		for (const _confIndex in _config) {
			for (const _confIndex2 in _config) {
				if (_confIndex !== _confIndex2) {
					if (_config[_confIndex].tableName === _config[_confIndex2].tableName) {
						return true;
					}
				}
			}
		}
		return false;
	})();

	let outputToArrayLength = 0;

	let toQuery = _config.length === 1 ? `query ${_config[0]._printed_var_defs || ""} {` : `{`;

	const vars = {};

	let returnRaw = false;
	for (const configIndex in _config) {
		const config = _config[configIndex];
		if (config.rawQuery) {
			toQuery += config.rawQuery;
			continue;
		}

		if (config.returnRaw) {
			returnRaw = true;
		}

		if (lib.notIn(config.tableName, lib.schema) && camelCase(config.tableName) in lib.schema) {
			// console.groupCollapsed("IMPLEMENTATION ON GET2");
			// console.trace();
			// console.groupEnd();
			config.tableName = camelCase(config.tableName);
		}

		if (lib.isObject(config.where) && !Array.isArray(config.where)) {
			// console.groupCollapsed("IMPLEMENTATION ON GET2");
			// console.trace();
			// console.groupEnd();
			config.where = ["AND", config.where];
		}

		if (!config.fields) config.fields = ["*"];
		outputToArrayLength++;

		let where = config.where ? ` where:  ${JSON.stringify(config.where).replace(/"([^"]+)":/g, "$1:")} ` : "";

		for (const key in config.variables) {
			where = where.replace(new RegExp(`\"\\$${key}"`, "g"), "$" + key);
		}

		toQuery += `${outputToArray ? `_${configIndex}:` : config.alias ? `${config.alias}:` : ""} ${"count_".if(countOnly)}${
			config.tableName
		} ${
			config.where || config.pagination
				? ` (${where}  ${
						!countOnly && config.pagination
							? `pagination: { ${Object.keys(config.pagination)
									.map(
										(key) =>
											` ${key}: ${
												typeof config.pagination[key] === "string" &&
												// "pagination.order" is a enum of ASC | DESC , NOT "ASC" | "DESC"
												key !== "order"
													? `"${config.pagination[key]}"`
													: config.pagination[key]
											}`
									)
									.join(" ")} }`
							: ""
				  }) `
				: ""
		} ${`{ ${config.fields
			.map(
				(field) =>
					`${
						field === "*"
							? denestTable(config.tableName)
							: typeof field === "string"
							? field
							: Array.isArray(field)
							? field.join(" ")
							: ""
					}`
			)
			.join(" ")} }`.if(!countOnly)} `;

		if (config.variables) {
			for (const key in config.variables) {
				if (key in vars) {
					throw new Error("Duplicate variables. Refusing to query");
				}
			}
			Object.assign(vars, config.variables);
		}
	}

	toQuery += `}`;

	toQuery = toQuery.replace(/( )*where\:( )*\["AND",\{\}\]( )*/, "");
	toQuery = toQuery.replace("()", "");

	// console.log(toQuery);
	const argsList: Parameters<typeof query> = [
		toQuery,
		{
			outputToArrayLength: outputToArray ? outputToArrayLength : undefined,
			variables: vars
		},
		callback,
		devFilter
	];

	if (returnRaw) {
		return argsList;
	}

	return query<T>(...argsList);
}

declare global {
	interface Window {
		CACHER: {
			find(config: { input: getInput; reason: string }): any | null;
			register(config: { input: getInput; res: any }): void;
		};
	}
}
window.CACHER = new (class {
	private cache: {
		input: getInput;
		data: any;
		dependants?: [];
	}[] = [];

	find(config: { input: getInput; reason: string }) {
		return null;
		for (const inCacheIndex in this.cache) {
			// console.log("comparing", config.input, this.cache[inCacheIndex].input);
			if (isEqual(config.input, this.cache[inCacheIndex].input)) {
				console.groupCollapsed("DUPLICATE QUERY");
				console.log(config);
				console.trace();
				console.groupEnd();
				return this.cache[inCacheIndex].data;
			}
		}
		return null;
	}

	register(config: { input: getInput; res: any }) {
		this.cache.push({
			input: config.input,
			data: config.res
		});
	}
})();

export function get2<T = any>(
	_config: getInput | getInput[],
	callback?: (data: any) => void,
	opts?: { devFilter?: string; cacheReason?: string; cache?: boolean }
): Promise<T> {
	// if (!Array.isArray(_config) && opts?.cache !== false) {
	// 	const inCache = window.CACHER.find({
	// 		input: _config,
	// 		reason: opts?.cacheReason
	// 	});

	// 	if (inCache) {
	// 		return inCache;
	// 	} else {
	// 		const res = await _get2<T>(_config, callback, opts?.devFilter);
	// 		window.CACHER.register({ input: _config, res });
	// 		return res;
	// 	}
	// } else
	return _get2<T>(_config, callback, opts?.devFilter);
}

export async function get2Count(
	_config: getInput | getInput[],
	callback?: (data: any) => void,
	devFilter?: string
): Promise<number> {
	try {
		if (!Array.isArray(_config) && lib.isObject(_config)) {
			_config.alias = "res";
		}
		const { res } = await _get2(_config, callback, devFilter, true);
		return res || 0;
	} catch (error) {
		return 0;
	}
}
