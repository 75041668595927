type DbDataType =
	| "string"
	| "integer"
	| "datetime"
	| "date"
	| "boolean"
	| "decimal"
	| "float"
	| "enum"
	| "decimal ARRAY"
	| "text ARRAY"
	| "string ARRAY"
	| "time"
	| "integerArray";

export function whereFilterOptions(type: DbDataType, firstBlank = true): string[] {
	const strings = ["eq", "like", "in", "neq"];
	const numbers = ["eq", "lt", "gt", "lte", "gte", "in", "neq"];

	const options: { [key in DbDataType]: string[] } = {
		string: strings,
		integer: numbers,
		datetime: numbers,
		date: numbers,
		time: numbers,
		boolean: ["eq"],
		decimal: numbers,
		float: numbers,
		enum: ["eq", "neq"],
		"decimal ARRAY": strings,
		"text ARRAY": strings,
		"string ARRAY": strings,
		integerArray: strings
	};

	return [...(firstBlank ? [""] : []), ...options[type]];
}
