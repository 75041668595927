// EXPOSE BOOTSTRAP

import { Tooltip } from "bootstrap";

export default function initBs($) {
	$.fn.extend({
		tooltip: function (options) {
			return this.each(function () {
				const obj = $(this);

				const tooltip = new Tooltip(obj, options || {});

				obj.on("remove", function () {
					tooltip.dispose();
				});
			});
		}
	});
}
