// @ts-nocheck
import { editorDialog, xf } from "src/lib";
import { denestTable, get2 } from "./get";
import { dayjsUTC } from "./dayjs";
import { get_store_value } from "svelte/internal";
import { DEBUG_APP, preferences } from "src/store";
/**
 * factory form config
 * @param model
 * @param config
 * @returns {void}
 */
export function factoryFormConfig<T extends TschemaTableName = any>(
	model: T,
	config?: factoryFormConfigArgs<T>
): FormConfig {
	const schema = _.cloneDeep(lib.schema);

	config ||= {};
	config.generateAsyncOptions ||= true;

	const table = schema[model];

	const out: FormConfig = {
		type: "Form",
		id: model,
		items: []
	};

	const outByGroup: { [key: string]: FormConfig[] } = {};

	for (const [key, def] of Object.entries(table)) {
		if (key === "Attachment" || def.reverse) continue;
		if (!outByGroup[def.group?.name]) outByGroup[def.group?.name] = [];

		const config: FormConfig = {
			...def,
			id: key,
			type: !!def.foreign
				? "SearchSelect"
				: {
						string: "Text",
						boolean: "Checkbox",
						integer: "Number",
						decimal: "Number",
						float: "Number",
						datetime: "DateTime",
						date: "Date",
						enum: "Select",
						link: "SearchSelect",
						externalLink: "SearchSelect",
						"text ARRAY": "TextList",
						time: "Time"
				  }[def.type]
		};

		for (const part of ["foreign", "link", "externalLink"]) {
			if (def[part]) {
				config[part] = def[part];
				config.tableName = def[part]?.table || def[part]?.name;
			}
		}

		if (def?.meta?.dataType?.type === "currency") {
			config.currency = true;
		}

		outByGroup[def.group?.name].push(config);
	}

	// if (config?.groupOnly) {
	// 	for (const group in outByGroup) {
	// 		if (config.groupOnly)
	// 	}
	// }

	for (const [group, items] of Object.entries(outByGroup)) {
		// @ts-ignore
		out.items.push({
			id: group || "undefined",
			label: group || "undefined",
			type: "Group",
			items: items.filter((item) => !["id", "updated_at", "created_at"].includes(item.id))
		});
	}

	for (const group of out.items) {
		for (const item of group.items) {
			if (item.type === "SearchSelect") {
				item.values = [];
				if (config.generateAsyncOptions) {
					const foreignName =
						schema[model][item.id]?.foreign?.table ||
						schema[model][item.id]?.link?.table ||
						schema[model][item.id]?.externalLink?.name ||
						item.id;
					const defaults: stringOf<asyncConfigType> = {
						default: {
							tableName: foreignName,
							fields: [denestTable(foreignName as TschemaTableName, 0)]
						},
						Employee: {
							tableName: "Employee",
							fields: ["id", "firstname", "lastname"],
							formatter: (data) => lib.convertTemplate(lib.getDefaultFormatter("employee"), data),
							filterField: "firstname",
							where: {
								status: {
									eq: "A"
								}
							}
						},
						Address: {
							tableName: "Address",
							fields: ["id", "street1", "street2", "city"],
							formatter: (obj) =>
								// @ts-ignore
								`${obj.street1}${obj.street2 ? ` ${obj.street2}` : ""}, ${obj.city}`, //Object.values(obj).join(", "),
							filterField: "street1"
						}
					};

					item.asyncConfig = defaults[foreignName] || defaults.default;
				}
			} else if (item.type === "Select") {
				item.values = schema[model][item.id]?.enum?.values;
			}
		}
	}

	for (const field of config?.destructureReverse || []) {
		if (field in schema[model]) {
			out.items.push({
				id: `destrcutured_${field}`,
				type: "Group",
				label: schema[model][field].niceName || field,
				columns: 1,
				buttons: [
					{
						icon: "add",
						action(masterContext: Writable<any>) {
							editorDialog({
								tableName: field,
								callback(id) {
									masterContext.update((state) => {
										state[config.id] = [...(state[config.id] || []), { id: String(id) }];
										return state;
									});
								},
								hooks: {
									tableConfig(formConfig: FormConfig) {
										for (const group of formConfig.items) {
											for (const item of group.items) {
												if (item.id === model) {
													item.hidden = true;
												}
											}
										}
										return formConfig;
									}
								},
								preMutate(ctx) {
									ctx[model] = get_store_value(masterContext).id;
									return ctx;
								}
							});
						}
					}
				],
				items: [
					{
						type: "Reverse",
						id: field,
						tableName: model
					}
				]
			});
		}
	}

	return out;
}

const defaultIgnoreTableNames: TschemaTableName[] = ["Address", "State", "City"];

export async function preloadSearchSelectValues(
	config: FormConfig,
	options?: preloadSearchSelectValuesOptions
): Promise<FormConfig> {
	if (options && options.ignoreAll) return config;
	const defaultOptions = {
		includeDefaultIgnoreTableNames: true,
		ignore: defaultIgnoreTableNames
	};
	if (options) {
		for (const [key, value] of Object.entries(options)) {
			if (typeof value === "undefined") {
				options[key] = defaultOptions[key];
			}
		}
	} else {
		options = defaultOptions;
	}
	if (!options.ignore) options.ignore = defaultIgnoreTableNames;
	if (!!options.ignore && options.includeDefaultIgnoreTableNames) {
		options.ignore = [...defaultIgnoreTableNames, ...options.ignore];
	}
	for (const group of config.items) {
		item_loop: for (const item of group.items) {
			if (item.type !== "SearchSelect") continue item_loop;
			if (options.ignore.includes(item.id)) continue item_loop;
			// if (defaultIgnoreTableNameFieldMap[])

			const fetchConf = {
				...(item.asyncConfig || {
					tableName: item.id
				}),
				alias: "res"
			};

			const { res: data } = await get2({
				...fetchConf,
				fields: [...(fetchConf.fields ? fetchConf.fields : ["*"])]
			});

			const values = [];
			if (data) {
				for (const option of data) {
					values.push({
						value: +option.id,
						label: lib.convertTemplate(lib.getDefaultFormatter(item.tableName || item.id), option)
					});
				}
				if (lib.sv(DEBUG_APP)) {
					console.log(values);
				}
			}
			item.values = values;
		}
	}
	return config;
}

export function factoryTabulatorColumns<T extends TschemaTableName>(config: {
	tableName: T;
	destructureForeign?: TabulatorAjaxDestructureForeignOption[];
}) {
	const schema = _.cloneDeep(lib.schema);

	const { destructureForeign, tableName } = config;
	// @ts-ignore
	const _cols: Tabulator.ColumnDefinition[] = Object.keys(schema[tableName])
		.map((field): Tabulator.ColumnDefinition => {
			const def: TschemaFieldDefinition = schema[tableName][field];
			if (def.reverse) {
				// @ts-ignore
				return false;
			}
			return {
				field,
				title: field.pretty,
				// @ts-ignore
				headerFilter: (function () {
					if (["id", "created_at", "updated_at"].includes(field) || ["foreign", "link", "externalLink"].includes(def.type)) {
						return false;
					}
					return "input";
				})(),
				visible: !["id", "created_at", "updated_at"].includes(field),
				...(!!def.foreign || !!def.link || !!def.externalLink
					? {
							formatter(cell) {
								const data = cell.getValue();
								if (!data || !data[0]) return "";
								// @ts-ignore
								return lib.convertTemplate(lib.getDefaultFormatter(field), data[0]);
							}
					  }
					: {}),
				...(["date", "time", "datetime"].includes(def.type)
					? {
							formatter(cell) {
								return cell.getValue() ? dayjsUTC(cell.getValue()).format(get_store_value(preferences).units[def.type]) : "";
							}
					  }
					: {})
			};
		})
		.filter(Boolean);

	for (const dF of destructureForeign || []) {
		for (const colIndex in _cols) {
			const col = _cols[colIndex];
			if (col.field === dF.field) {
				const foreignName =
					schema[col.field]?.foreign?.table ||
					schema[col.field]?.link?.table ||
					schema[col.field]?.externalLink?.name ||
					col.field;

				const newFields: Tabulator.ColumnDefinition[] = Object.entries(schema[foreignName])
					// filter out reverse
					// @ts-ignore
					.filter(([field, def]) => !def.reverse)
					// filter out predefined subfields
					.filter(([field]) => (dF.subfields ? dF.subfields.includes(field) : true))
					.map(([field, def]) => {
						return {
							// @ts-ignore
							title: `${col.field}/${def.niceName || field}`,

							field: `${col.field}.${field}`,
							formatter(cell) {
								// console.log(cell.getData());
								return lib.tryOr(() => cell.getData()[col.field][0][field], "");
							},
							visible: !["created_at", "updated_at"].includes(field),
							headerSort: false,
							// @ts-ignore
							headerFilter:
								["created_at", "updated_at"].includes(field) || ["foreign", "link", "externalLink"].includes(def.type)
									? false
									: "input"
						};
					});

				if (dF.position === "end") {
					_cols.splice(+colIndex, 1);
					_cols.push(...newFields);
				} else {
					_cols.splice(+colIndex, 1, ...newFields);
				}
			}
		}
	}

	return _cols;
}

export function factoryTabulatorAjax<T extends TschemaTableName>(
	tableName: T,
	tableConfig: Tabulator.Options = {},
	processors?: TabulatorAjaxProcessors,
	destructureForeign?: TabulatorAjaxDestructureForeignOption[]
): Tabulator.Options {
	const schema = _.cloneDeep(lib.schema);

	const _cols = factoryTabulatorColumns({
		tableName,
		destructureForeign
	});
	return {
		columns: processors.columns ? processors.columns(_cols) : _cols,
		// @ts-ignore
		HARD_UPDATE_ONLY: true,
		ajaxURL: tableName,
		ajaxFiltering: true,
		ajaxSorting: true,
		pagination: true,
		paginationMode: "remote",
		paginationSize: 10,
		paginationSizeSelector: [5, 10, 25, 50, 100],
		autoResize: true,
		ajaxRequestFunc(
			url: TschemaTableName,
			config: Tabulator.AjaxConfig,
			params: {
				page: number;
				size: number;
				filters?: { field: string; type: "like"; value: string }[];
				sorters?: [{ field: string; dir: string }];
				fields?: string[];
				where?: [string, any];
			}
		) {
			return new Promise(async function (resolve, reject) {
				try {
					const where = (function () {
						const out = {};
						if (Array.isArray(params.filters)) {
							for (const filter of params.filters) {
								if (!filter.field.includes(".")) {
									if (!out[filter.field]) {
										out[filter.field] = {};
									}
									const def = schema[tableName][filter.field];
									if (["foreign", "externalLink", "link"].includes(def.type)) {
										out[filter.field][
											// @ts-ignore
											xf.dynamicSwitch(tableName, {
												default: "name"
											})
										] = { like: `%${filter.value.replace(/[^0-z]/gi, "")}%` };
									} else if ((["date", "datetime", "time"] as TschemaDataType[]).includes(def.type)) {
										out[filter.field]["eq"] = filter.value;
									} else {
										out[filter.field]["like"] = `%${filter.value}%`;
									}
								} else {
									// this below creates nested values if they don't exist
									filter.field.split(".").reduce((p, c) => {
										p[c] = p[c] || {};

										return p[c];
									}, out);

									let current = out;
									for (const part of filter.field.split(".")) {
										current = current[part];
									}
									current["like"] = `%${filter.value}%`;
								}
								// TODO:FOREIGN
								// if (filter.field === "id" || filter.field.endsWith("_id")) {
								// out[filter.field].eq = filter.value.replace(/[^0-9]/gi, "");
								// } else {
								// out[filter.field][
								// 	xf.dynamicSwitch(filter.type, {
								// 		default: "eq"
								// TODO:GLIZER
								// add `ilike` option into the WhereFilterModifier
								// like: "ilike"
								// 	})
								// ] = ["like", "ilike"].includes(filter.type)
								// 	? `%${filter.value}%`
								// 	: filter.value;
								// }
							}
						}
						return out;
					})();

					Array.ensure(params.where).forEach(([path, value]) => {
						_.merge(where, lib.emptyObjectFromString(path, value));
					});

					const sorters = (function () {
						const out: any = {};
						if (params?.sorters?.[0]) {
							const [{ dir, field }] = params.sorters;
							(out.order = dir.toUpperCase()), (out.order_by = field);
						}
						return out;
					})();

					const getConfig = (count: boolean = false) => {
						const out = {
							alias: "res",
							where,
							tableName,
							fields: [
								denestTable(tableName, 0),
								(destructureForeign || [])
									.map((table) => {
										const foreignName =
											schema[table]?.foreign?.table || schema[table]?.link?.table || schema[table]?.externalLink?.name || table;

										if (foreignName in schema) {
											return denestTable(foreignName, 0, 0, table);
										} else return false;
									})
									.filter(Boolean)
							],
							...{ ...(params?.fields ? { fields: params.fields } : {}) },
							...(count
								? { fields: ["id"] }
								: {
										pagination: {
											limit: params.size,
											offset: (params.page - 1) * params.size,
											...sorters
										}
								  })
						};

						return processors.getConfig ? processors.getConfig(out, count) : out;
					};

					const res = await get2(getConfig());
					// let count = ((await get2(getConfig(true))).res || []).length;

					const count = (await lib.get2Count(getConfig(true))) || 0;
					const out = {
						data: processors.data ? processors.data(res.res || []) : res.res || [],
						// count
						last_page: Math.ceil(count / params.size)
						// last_page: 37,
						// max_pages: 37
					};
					// console.log(out);
					// debugger;
					resolve(out);
				} catch (error) {
					console.error(error);
					reject(error);
				}
			});
		},
		...(tableConfig || {}),
		ajaxError(xhr, status, err) {
			console.log(xhr, status, err);
			return true;
		}
	};
}
