import { get_store_value, noop, safe_not_equal } from "svelte/internal";
import { StartStopNotifier, Subscriber, Unsubscriber, Updater } from "svelte/store";

type Invalidator<T> = (value?: T) => void;

type SubscribeInvalidateTuple<T> = [Subscriber<T>, Invalidator<T>];

const subscriber_queue = [];

declare global {
	namespace SvelteExtended {
		interface store_Writable_args<T> {
			safe_not_equal?: <T>(a: T, b: T) => boolean;
		}

		type store_Writable<T> = Writable<T> & {
			run_subscribers(): void;
			get(): T;
		};
	}
}

function writable<T>(
	value?: T,
	start: StartStopNotifier<T> = noop,
	args?: SvelteExtended.store_Writable_args<T>
): SvelteExtended.store_Writable<T> {
	if (typeof start != "function") {
		start = noop;
	}
	args ||= {};
	args.safe_not_equal ||= safe_not_equal;

	let stop: Unsubscriber;
	const subscribers: Set<SubscribeInvalidateTuple<T>> = new Set();

	function run_subscribers(warnIfEmpty = true) {
		if (stop) {
			const run_queue = !subscriber_queue.length;
			for (const subscriber of subscribers) {
				subscriber[1]();
				subscriber_queue.push(subscriber, value);
			}
			if (run_queue) {
				for (let i = 0; i < subscriber_queue.length; i += 2) {
					subscriber_queue[i][0](subscriber_queue[i + 1]);
				}
				subscriber_queue.length = 0;
			} else {
				console.groupCollapsed("no run_queue");
				console.groupEnd();
			}
		} else {
			if (warnIfEmpty) {
				console.log("tried to run empty subscribers");
				lib.getStackTrace();
			}
		}
	}

	function set(new_value: T): void {
		if (args.safe_not_equal(value, new_value)) {
			value = new_value;
			run_subscribers(false);
		}
	}

	function update(fn: Updater<T>): void {
		set(fn(value));
	}

	function subscribe(run: Subscriber<T>, invalidate: Invalidator<T> = noop): Unsubscriber {
		const subscriber: SubscribeInvalidateTuple<T> = [run, invalidate];
		subscribers.add(subscriber);
		if (subscribers.size === 1) {
			stop = start(set) || noop;
		}
		run(value);

		return () => {
			subscribers.delete(subscriber);
			if (subscribers.size === 0) {
				stop();
				stop = null;
			}
		};
	}

	return {
		set,
		update,
		subscribe,
		run_subscribers,
		get() {
			return get_store_value({ subscribe });
		}
	};
}

function writable_ls_sync<T = any>(
	id: string,
	initValue: T = null,
	startStop = _.selfop(_.noop),
	sne = safe_not_equal,
	storage: Storage = localStorage
) {
	const val = _.attempt(() => JSON.parse(storage.getItem(id)));
	const { subscribe, update, set } = writable<T>(_.isError(val) ? initValue : val, startStop);
	const get = get_store_value.bind(null, { subscribe });

	return {
		subscribe,
		update,
		set(newVal) {
			if (sne(get(), newVal)) {
				storage.setItem(id, JSON.stringify(newVal));
				set(newVal);
			} else {
				console.log("seq fail", newVal, get());
			}
		}
	};
}

function writable_Set<T>(): Readable<Set<T>> {
	const store_set = new Set<T>();
	const store = writable(store_set);

	function add_item(prop, val) {}

	function update_item(prop, val) {}

	return { subscribe: store.subscribe };
}

export const svelte_extended = {
	store: {
		writable,
		writable_ls_sync
	}
};
